import { createSlice } from '@reduxjs/toolkit'

const wsStateSlice = createSlice({
  name: 'ws-state',
  initialState: false ,
  reducers: {
    enable: () => true,
    disable: () => false,
  },
})

export const wsStateActions = wsStateSlice.actions
export default wsStateSlice.reducer
