import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import CompetitionIcon from '@/assets/images/competition.svg'
import AnnouncementIcon from '@/assets/images/announcement.svg'
import AuditIcon from '@/assets/images/pen.svg'
import SymbolIcon from '@/assets/images/variety.svg'
import GroupIcon from '@/assets/images/group.svg'
import SettingIcon from '@/assets/images/setting.svg'
import PeopleIcon from '@/assets/images/people.svg'
import SignalIcon from '@/assets/images/wifi-tethering.svg'
import ReportIcon from '@/assets/images/report.svg'
import MoneyLiquidityIcon from '@/assets/images/money-liquidity.svg'
import { useMemo } from 'react'
import { AuthKeyType, useBrokerPermission } from '@/hooks/useBrokerPermission'
import { useBrokerSubscriptions } from '@/hooks/useBrokerSubscription'
import { SubscriptionItems } from '@/utils/enum'
import { isSofinqDomain } from '@/utils/helper'

type SubscriptionKeyType = SubscriptionItems[keyof SubscriptionItems];

type AsideItem = {
  name: string
  label: string
  icon: string
  badge?: number
  subscribeKey?: SubscriptionKeyType
  authKey?: AuthKeyType
  children: Array<{ name: string, label: string, badge?: number, authKey?: AuthKeyType, subscribeKey?: SubscriptionKeyType }>
}

const useAside = () => {
  const { t } = useTranslation()
  const auditCount = useSelector((state: RootState) => state.auditCount)
  const { checkSubscription } = useBrokerSubscriptions()
  const { checkAuth } = useBrokerPermission()

  const asideList: AsideItem[] = [
    {
      name: 'setting',
      label: t('setting'),
      icon: SettingIcon,
      children: [
        { name: 'identity-verification', label: t('identityVerification'), authKey: 'aside_menu-indentity_verification', subscribeKey: 'IDENTITY_VERIFICATION' },
        { name: 'platform-settings', label: t('platformSettings'), authKey: 'aside_menu-platform_settings' },
        { name: 'deposit', label: t('depositManage'), authKey: 'aside_menu-deposit_management' },
        { name: 'withdraw', label: t('withdrawManage'), authKey: 'aside_menu-withdraw_management' },
      ],
    },
    {
      name: 'liquidity',
      label: t('liquidity'),
      icon: MoneyLiquidityIcon,
      authKey: 'aside_menu-liquidity',
      children: [],
    },
    {
      name: 'investor',
      label: t('investor'),
      icon: PeopleIcon,
      authKey: 'aside_menu-investor',
      children: [],
    },
    {
      name: 'signal',
      label: t('signalManagement'),
      icon: SignalIcon,
      authKey: 'aside_menu-signal',
      children: [],
    },
    {
      name: 'symbol',
      label: t('symbolManager'),
      icon: SymbolIcon,
      authKey: 'aside_menu-symbol',
      children: [],
    },
    {
      name: 'rule',
      label: t('groupAndRuleSetting'),
      icon: GroupIcon,
      children: [
        { name: 'group', label: t('tradeGroup'), authKey: 'aside_menu-rule-group' },
        { name: 'symbol', label: t('symbolRule'), authKey: 'aside_menu-rule-symbol' },
        { name: 'liquidity', label: t('liquidityRule'), authKey: 'aside_menu-rule-liquidity' },
        { name: 'interest', label: t('interestRule'), authKey: 'aside_menu-rule-interest' },
        { name: 'fee', label: t('feeRule'), authKey: 'aside_menu-rule-fee' },
      ],
    },
    {
      name: 'competition',
      label: t('competitionCampaign'),
      icon: CompetitionIcon,
      authKey: 'aside_menu-competition',
      children: [],
    },
    {
      name: 'report',
      label: t('report'),
      icon: ReportIcon,
      authKey: 'aside_menu-report',
      children: [
        { name: 'investor-profit', label: t('investorProfit') },
        { name: 'symbol', label: t('symbolProfit') },
        { name: 'position', label: t('position') },
        { name: 'closed-trades', label: t('closedTrades') },
        { name: 'order', label: t('order') },
      ],
    },
    {
      name: 'audit',
      label: t('review'),
      icon: AuditIcon,
      badge: auditCount.identityVerification + auditCount.deposit + auditCount.withdraw,
      children: [
        { name: 'identity-verification', label: t('identityVerification'), badge: auditCount.identityVerification, authKey: 'aside_menu-identity-verification_review', subscribeKey: 'IDENTITY_VERIFICATION' },
        { name: 'deposit', label: t('deposit'), badge: auditCount.deposit, authKey: 'aside_menu-deposit_review' },
        { name: 'withdraw', label: t('withdrawal'), badge: auditCount.withdraw, authKey: 'aside_menu-withdraw_review' },
      ],
    },
  ]
  if (isSofinqDomain()) {
    asideList.push({
      name: 'announcement-setting?state=published',
      label: t('announcementCenter'),
      icon: AnnouncementIcon,
      authKey: 'announcement-setting',
      children: [],
    })
  }

  const authAsideList = useMemo(() => {

    let result: AsideItem[] = []
    // white & Tim
    const isVisible = ({ authKey, subscribeKey }: { authKey?: AuthKeyType, subscribeKey?: SubscriptionKeyType }) => {
      if (subscribeKey && !checkSubscription(subscribeKey as string)) {
        return false
      }
      if (authKey && !checkAuth({ key: authKey, authType: 'read' })) {
        return false
      }
      return true
    }

    asideList.forEach(parenItem => {
      let tempParent: AsideItem
      if (!isVisible({ authKey: parenItem.authKey, subscribeKey: parenItem.subscribeKey as SubscriptionKeyType })) {
        return
      }
      tempParent = { ...parenItem, children: [] }
      parenItem.children.forEach(child => {
        if (isVisible({ authKey: child.authKey, subscribeKey: child.subscribeKey as SubscriptionKeyType })) {
          tempParent.children.push(child)
        }
      })
      result.push(tempParent)
    })
    return result
  }, [asideList, checkAuth, checkSubscription])

  return { authAsideList }
}

export default useAside
