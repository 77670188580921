import { getDefaultLanguage } from '@/assets/locales/i18n'

function ObjectCombine<T extends Object> (rawValue: T, value?: Partial<T>): T {
  type Keys = keyof typeof value

  if (value === undefined) return rawValue

  if (Object.prototype.toString.call(rawValue) !== '[object Object]') return value as T
  else {
    (Object.keys(rawValue) as Keys[]).forEach(key => {
      if (rawValue.hasOwnProperty(key)) rawValue[key] = ObjectCombine(rawValue[key], value[key])
    })
  }

  return rawValue
}

export const UserInfo = (value?: Common.User): Required<Common.User> => {
  const result = {
    id: '',
    headshot: '',
    username: '',
    email: '',
    language: getDefaultLanguage(),
  }

  return ObjectCombine(result, value)
}

export const Competition = (value?: Common.Competition): Required<Common.Competition> => {
  const result = {
    id: '',
    title: '',
    icon: '',
    timeStart: '',
    timeEnd: '',
    numberOfReward: 0,
    highestReward: '',
    type: '' as Common.CompetitionType,
    status: 'ENDED' as Common.CompetitionStatus,
    description: '',
    terminateReason: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
    fee: '',
    promote: {
      conditionDepositAmount: '',
    },
    trade: {
      isReal: false,
      investAmount: '',
      isSpecifySymbol: false,
      stopRobot: false,
      minVolume: '',
      maxLossThresholdEnable: false,
      maxLossThresholdPercentage: '',
      symbols: [] as Array<Common.CompetitionSymbol>,
      lossAlertPercentage: '',
      maxMultiplierEnable: false,
      maxMultiplier: '1',
      enforceStopLoss: false,
      enforceStopLossPercentage: '',
      requirePreviousParticipation: false,
      requiredPreviousCompetitionId: '0',
      requireRegistration: false,
      stripeProductLink: '',
    },
    numberOfJoined: 0,
    heroImageUrl: '',
    heroImageLink: '',
    heroImageAltText: '',
    adImageUrl: '',
    adImageLink: '',
    adImageAltText: '',
  }

  return ObjectCombine(result, value)
}

export const CommonDepositOrder = (value?: Partial<Common.DepositOrder>): Common.DepositOrder => {
  const result: Common.DepositOrder = {
    id: '',
    type: '' as any,
    time: '',
    amount: '',
    status: '' as any,
    exchangeRate: '',
    fee: '',
    currency: '',
    paidAmount: '',
    userDepositInfo: '',
    userComment: '',
    brokerComment: '',
    feeType: '' as Common.FeeType,
    adminSettingFee: '',
    operateBy: '',
    user: {
      id: '',
      username: '',
      email: '',
      countryCode: '',
      phone: '',
      kyc: false,
    },
    transferInfoForWire: {
      bankName: '',
      branchName: '',
      bankAddress: '',
      bankPhone: '',
      bankCodeType: '' as any,
      bankCode: '',
      payeeAccount: '',
      payeeName: '',
      payeePhone: '',
      payeeAddress: '',
    },
    transferInfoForCrypto: {
      mainnetName: '',
      walletAddress: '',
      currencyAliasName: '',
      imageUrl: ''
    },
    transferInfoForHdwallet: {
      network: '',
      walletAddress: '',
    },
    transferInfoForPaymentChannel: {
      name: '',
      needBrokerVerify: true,
      thirdPartyPaymentAmount: '',
      thirdPartyPaymentCurrency: '',
      thirdPartyPaymentMsg: '',
      thirdPartyPaymentOrderId: '',
      thirdPartyPaymentPageUrl: '',
    },
  }

  const obj = ObjectCombine(result, value)
  return obj
}

export const CommonWithdrawOrder = (value?: Partial<Common.WithdrawOrder>): Common.WithdrawOrder => {
  const result: Common.WithdrawOrder = {
    id: '',
    type: '' as any,
    time: '',
    amount: '',
    status: '' as any,
    exchangeRate: '',
    fee: '',
    feeType: '' as Common.FeeType,
    adminSettingFee: '',
    currency: '',
    paidAmount: '',
    userComment: '',
    brokerComment: '',
    user: {
      id: '',
      username: '',
      email: '',
      countryCode: '',
      phone: '',
      kyc: false
    },
    transferInfoForWire: {
      name: '',
      address: '',
      username: '',
      accountNumber: '',
      bankCodeType: '' as any,
      bankCode: '',
      id: '',
      bankPhone: '',
    },
    transferInfoForCrypto: {
      mainnetName: '',
      walletAddress: '',
      currencyAliasName: '',
      imageUrl: ''
    },
    operateBy: '',
  }

  const obj = ObjectCombine(result, value)
  return obj
}

export const CommonSignal = (value?: Partial<Common.Signal.Signal>): Common.Signal.Signal => {
  const result: Common.Signal.Signal = {
    id: '',
    name: '',
    describe: '',
    headshot: '',
    enabledAt: '',
    computed: {
      numOfFollowers: 0,
      investmentOfFollow: '',
      positionsCount: 0,
    },
    followSetting: {
      investmentMin: '',
      investmentMax: '',
      profitSharePercent: '',
    },
    performance: {
      activeLevel: 0,
      principal: '',
      profit: '',
      drawdown: '',
      drawdownRatio: '',
      duration: '',
      tradePerWeek: '',
      avgHoldingDuration: '',
      apr7: '',
      apr30: '',
      apr180: '',
      sharpeRatio: '',
      volMax: '',
      volMin: '',
      volAvg: '',
      volTotal: '',
      winRate: '',
      tradesTotal: 0,
      updatedAt: '',
      profitDatas: [],
      rateOfReturn: '',
    },
    symbols: [],
    byBroker: {
      show: false
    },
    provider: {
      id: '',
      name: '',
      headshot: '',
    },
    averageMonthlyTradeVolume: '',
    platform: 'MT4',
    activeLevel: 0
  }

  const obj = ObjectCombine(result, value)
  return obj
}

export const ProfitShareRecord = (value?: Partial<Common.ProfitShareRecord>): Common.ProfitShareRecord => {
  const result: Common.ProfitShareRecord = {
    id: '',
    signalId: '',
    signalName: '',
    robotId: '',
    robotName: '',
    followType: '',
    settleTime: '',
    profit: '',
    hwm: '',
    profitShare: '',
    profitSharePercent: '',
    settleDuringStart: '',
    settleDuringEnd: '',
    status: '',
  }

  const obj = ObjectCombine(result, value)
  return obj
}
