import { Fragment, memo, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { useLocation, Link } from 'react-router-dom'
import { SFText, SFSVG, SFIconImage } from '@otsofintech/sofinx-ui'
import useAside from './hooks/useAside'
import Admin from '@/assets/images/freeze-color/admin.svg'
import ExpandIcon from '@/assets/images/expand.svg'
import CircleIcon from '@/assets/images/circle.svg'
import UserContent from './content-user'
import FooterContent from './content-footer'
import * as S from './style'

interface AsideMenuProps {
  isPin: boolean
  onPin: () => void
}

type ToggleMap = {
  [props: string]: boolean
}

const getToogleMap = (list: Array<{ name: string, [props: string]: any }>): {[props: string]: boolean } => {
  const result = {} as ToggleMap
  list.forEach(item => {
    result[item.name] = false
  })
  return result
}

const AsideMenu = memo(({ isPin, onPin }: AsideMenuProps) => {
  const username = useSelector((state: RootState) => state.userInfo.username)
  const location = useLocation()
  const { authAsideList } = useAside()

  // 開關父層
  const [ toogleMap, setToogleMap ] = useState(getToogleMap(authAsideList))
  useEffect(() => {
    const parent = location.pathname?.split('/')[1]
    parent && setToogleMap(prev => ({ ...prev, [parent]: true }))
  }, [])
  const handleToggle = (name: string) => {
    setToogleMap(prev => {
      const isOpen = !prev[name]
      const result = { ...prev }
      if (isOpen) {
        authAsideList.forEach(item => {
          if (item.name !== activeParent) result[item.name] = false
        })
      }
      result[name] = isOpen
      return result
    })
  }

  // 當前父層
  const activeParent = useMemo(() => {
    const name = location.pathname.split('/')[1]
    const target = authAsideList.find(parent => parent.name === name)
    return target ? target.name : ''
  }, [location.pathname])

  // 切換路由時, 關閉所有展開
  useEffect(() => {
    setToogleMap(prev => {
      const result = { ...prev }
      authAsideList.forEach(item => {
        if (item.name !== activeParent) result[item.name] = false
      })
      return result
    })
  }, [activeParent])

  return (
    <S.AsideWrapper ispin={isPin ? 1 : 0}>
      {/* logo */}
      <S.TitleContent style={{ display: 'flex', alignItems: 'center' }}>
        <Link to="/">
          <S.FlexBox className="lightlogo">
            <S.BigLogo src={`/light_logo?$${Date.now()}`}/>
            <img src={Admin} alt="" />
          </S.FlexBox>
          <SFIconImage className="logo" src={`/favicon?$${Date.now()}`} width="32px" height="32px" />
        </Link>

        <S.Toggle className="toggle" ispin={isPin ? 1 : 0} onClick={onPin} src={ExpandIcon} color="blank" />
      </S.TitleContent>

      {/* 使用者資料 */}
      <UserContent username={username} isPin={isPin} />

      {/* 選單 */}
      <S.AsideContent>
        { authAsideList.map(item => item.children.length ?
          <Fragment key={item.name}>
            <S.AsideItem key={item.name} className={activeParent === item.name ? 'active' : ''} onClick={() => handleToggle(item.name)}>
              <S.IconWrap>
                <SFSVG src={item.icon} color={'grey200'} width="24px" height="24px" />
              </S.IconWrap>

              <SFText mx="12px" width={1} display="flex" justifyContent="space-between" className="menu-label" level={2}>
                <S.Span>{item.label}</S.Span>
                { item.badge ? <S.Badge>{ item.badge }</S.Badge> : null }
              </SFText>

              <div className="expand">
                <S.Expand src={ExpandIcon} active={toogleMap[item.name] ? 1 : 0} color="grey200" />
              </div>
            </S.AsideItem>
            {/* 子項目 */}
            <S.Collapse isOpen={toogleMap[item.name]} item={item.children.length}>
              <S.AsideChilds className="aside-child">
                { item.children.map(child => (
                  <S.AsideChild key={child.name} to={`/${item.name}/${child.name}`} activeClassName="active">
                    <SFText display="flex" alignItems="center">
                      <SFSVG mr="8px" src={CircleIcon} color={`/${item.name}/${child.name}` === location.pathname ? 'white' : 'grey30' } />
                      <S.Span>{child.label}</S.Span>
                    </SFText>
                    { child.badge ? <S.Badge  active={`/${item.name}/${child.name}` === location.pathname || `/${item.name}/${child.name}` === location.pathname.substring(0, location.pathname.lastIndexOf('/'))}>{ child.badge }</S.Badge> : null }
                  </S.AsideChild>
                ))}
              </S.AsideChilds>
            </S.Collapse>
          </Fragment>
          :
          <S.AsideLink key={item.name} to={`/${item.name}`} activeClassName="active">
            <S.IconWrap>
              <SFSVG src={item.icon} color={ location.pathname.startsWith(`/${item.name}`) ? 'blank' : 'grey200'} className="meun-icon" />
            </S.IconWrap>
            <SFText ml="12px" width={1} display="flex" justifyContent="space-between" className="menu-label" level={2}>
              <S.Span>{item.label}</S.Span>
              { item.badge ? <S.Badge>{ item.badge }</S.Badge> : null }
            </SFText>
          </S.AsideLink>
        )}
      </S.AsideContent>

      {/* footer */}
      <FooterContent />
    </S.AsideWrapper>
  )
}, (prev, next) => prev.isPin === next.isPin)

export default AsideMenu
