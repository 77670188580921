import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 交易品種清單
export const GetConfigSymbolService = (): ApiService<Config.GetSymbolList.Response> => {
  return request.get('/config/symbol')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetConfigSymbolService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
// 流動性列表
export const GetLiquidities = (): ApiService<Liquidity.Response> => {
  return request.get('/config/liquidity')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetLiquidities', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
// 單隻流動性
export const GetLiquidity = (liquidityId: string): ApiService<Liquidity.LiquidityDetail.Response> => {
  return request.get(`/config/liquidity/${liquidityId}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetLiquidity', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
