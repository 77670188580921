import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enUS from './en-US.json'
import zhTW from './zh-TW.json'
import zhCN from './zh-CN.json'
import myMM from './my-MM.json'
import jaJP from './ja-JP.json'
import viVN from './vi-VN.json'
import thTH from './th-TH.json'

type Translate = keyof typeof resources

export const languageList = [
  { short: '繁中', text: '繁體中文', value: 'zh-TW' },
  { short: '简中', text: '简体中文', value: 'zh-CN' },
  { short: 'EN', text: 'English', value: 'en-US' },
  { short: 'MY', text: 'မြန်မာစကား', value: 'my-MM' },
  { short: 'Tiếng Việt', text: 'Tiếng Việt', value: 'vi-VN' },
  { short: '日本語', text: '日本語', value: 'ja-JP' },
  { short: 'TH', text: 'ภาษาไทย', value: 'th-TH' }
]

// i18next resource
export const resources = {
  'en-US': { translation: enUS },
  'zh-TW': { translation: zhTW },
  'zh-CN': { translation: zhCN },
  'my-MM': { translation: myMM },
  'vi-VN': { translation: viVN },
  'ja-JP': { translation: jaJP },
  'th-TH': { translation: thTH },
}

    
export const initLanguage = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: getDefaultLanguage(),   //預設語言
    fallbackLng: 'en-US',       //如果當前切換的語言沒有對應的翻譯則使用這個語言，
    interpolation: {
      escapeValue: false,
    },
  })

}

export function getDefaultLanguage () {
  const local = localStorage.getItem('language')
  const browser = resources[navigator.language as Translate] ? navigator.language : 'en-US'
  return local || browser
}

export default i18n
