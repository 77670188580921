import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 取得broker託管資金
export const GetCreditDepositService = (): ApiService<CreditDeposit.GetFunds.Res> => {
  return request.get('/trust_funds')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCreditDepositService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得broker託管資金紀錄
export const GetCreditDepositRecordsService = (query: CreditDeposit.GetRecords.Req): ApiService<CreditDeposit.GetRecords.Res> => {
  const params = toUnderline(deepCopy(query))
  return request.get('/trust_funds/records', { params })
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCreditDepositRecordsService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
