import styled from 'styled-components'
import { SFIconImage } from '@otsofintech/sofinx-ui'
import { NavLink } from 'react-router-dom'
import { SFSVG } from '@otsofintech/sofinx-ui'

export const AsideWrapper = styled.aside<{ ispin: 1 | 0 }>`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: ${ props => props.ispin ? '320px' : '88px' };
  height: 100vh;
  background-color: ${ props => props.theme.palette.blank };
  transition: width .2s;
  z-index: 20;
  overflow: hidden;

  & > * {
    transition: all .2s;
  }
  & .lightlogo {
    display: ${ props => props.ispin ? 'flex' : 'none' };
  }
  & .logo {
    display: ${ props => props.ispin ? 'none' : 'block' };
  }
  & .toggle {
    display: ${ props => props.ispin ? 'block' : 'none' };
  }
  & .userdiv {
    height: ${ props => props.ispin ? '74px' : '48px' };
  }
  & .usercard {
    height: ${ props => props.ispin ? '74px' : '48px' };
    padding: ${ props => props.ispin ? '16px 12px' : '12px' };
  }
  & .username {
    margin-left: ${ props => props.ispin ? '12px' : '0' };;
    display: ${ props => props.ispin ? 'block' : 'none' };
  }
  & .user-menu {
    display: ${ props => props.ispin ? 'block' : 'none' };
  }
  & .menu-label {
    display: ${ props => props.ispin ? 'flex' : 'none' };
  }
  & .expand {
    width: 24px;
    display: ${ props => props.ispin ? 'block' : 'none' };
  }
  & .footer {
    display: flex;
    opacity: ${ props => props.ispin ? '1' : '0' };
  }
  & .aside-child {
    display: ${ props => props.ispin ? 'block' : 'none' };
  }

  &:hover {
    width: 320px;

    & .lightlogo {
      display: flex;
    }
    & .logo {
      display: none;
    }
    & .toggle {
      display: block;
    }
    & .userdiv {
      height: 74px;
    }
    & .usercard {
      height: 74px;
      padding: 16px 12px;
    }
    & .username {
      margin-left: 12px;
      display: block;
    }
    & .userinfo {
      height: 42px;
    }
    & .user-menu {
      display: block;
    }
    & .menu-label {
      display: flex;
    }
    & .expand {
      width: 24px;
      display: block;
    }
    & .footer {
      opacity: 1;
    }
    & .aside-child {
      display: block;
    }
  }
`

export const IconWrap = styled.div`
  width: 24px;
  height: 24px;
`

export const Span = styled.span`
  word-break: keep-all;
  white-space: nowrap;
`

export const TitleContent = styled.div`
  padding: 16px 28px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const FlexBox = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`

export const BigLogo = styled(SFIconImage)`
  margin-right: 20px;
  width: 100px;
  height: 35px;
`

export const Toggle = styled(SFSVG)<{ ispin: 1 | 0 }>`
  @media screen and (max-width: 1199px) {
    display: none;
  }
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${ props => props.theme.palette.grey100 };
  transform: rotate(${ props => props.ispin ? '180' : '0' }deg);
  cursor: pointer;
  &:hover {
    background-color: ${ props => props.theme.palette.grey200 };
  }
`

export const AsideContent = styled.div`
  padding: 0 20px 24px;
  margin: auto;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: auto;

  & > *:first-child {
    margin-top: 0;
  }
`

export const AsideItem = styled.div`
  margin-top: 12px;
  padding: 12px;
  display: flex;
  width: 100%;
  height: 48px;
  color: ${ props => props.theme.palette.grey50 };
  border-radius: 8px;
  cursor: pointer;

  &.active {
    background: ${ props => props.theme.palette.grey20 };
  }

  &:hover {
    background: ${ props => props.theme.palette.grey20 };
  }
`

export const AsideLink = styled(NavLink)`
  margin-top: 12px;
  padding: 12px;
  display: flex;
  width: 100%;
  height: 48px;
  color: ${ props => props.theme.palette.grey50 };
  border-radius: 8px;

  &.active {
    background: linear-gradient(90.69deg, #00ACAC 0.6%, #74DCDC 99.4%) !important;
    box-shadow: 0px 0px 30px 6px #14BDBD26, 0px 0px 12px -3px #14BDBD;
    color: ${ props => props.theme.palette.white };
    font-weight: 600;
  }

  &:hover {
    background: ${ props => props.theme.palette.grey20 };
  }
`

export const AsideChilds = styled.div`
  & > *:first-child {
    margin-top: 12px;
  }
`

export const AsideChild = styled(NavLink)`
  margin-top: 4px;
  padding: 12px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 14px;
  color: ${ props => props.theme.palette.grey50 };
  border-radius: 8px;

  &:hover {
    background: ${ props => props.theme.palette.grey20 };
  }

  &.active {
    background: linear-gradient(90.69deg, #00ACAC 0.6%, #74DCDC 99.4%);
    box-shadow: 0px 0px 30px 6px #14BDBD26, 0px 0px 12px -3px #14BDBD;
    color: ${ props => props.theme.palette.white };
    font-weight: 700;
  }
`

export const Expand = styled(SFSVG)<{ active: number }>`
  margin-left: auto;
  width: 24px;
  height: auto;
  transform: rotate(${ props => props.active ? '90' : '0' }deg);
  transition: all .2s;
`

export const Collapse = styled.div<{ isOpen: boolean, item: number }>`
  max-height: ${ props => props.isOpen ? props.item * 56 + 'px' : 0 };
  overflow-x: visible;
  overflow-y: ${ props => props.isOpen ? 'unset' : 'auto' };
  transition: all .2s;
`

export const Badge = styled.div<{ active?: boolean }>`
  padding: 9px;
  height: 24px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: ${ props => props.active ? props.theme.palette.primary : props.theme.palette.blank };
  background-color: ${ props => props.active ? props.theme.palette.blank : props.theme.palette.primary };
  transition: all .2s;
`

