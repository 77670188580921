import styled from 'styled-components'
import useStatusMap from './useStatusMap'
import { SFSVG } from '@otsofintech/sofinx-ui'
import ErrorIcon from '@/assets/images/error-outline.svg'
import CloseIcon from '@/assets/images/close.svg'

interface CreditDepositBottomNotifyProps {
  isPin: boolean
  isLogin: boolean
}

const StyleWrapper = styled.div<{
    ispin: boolean,
    islogin: boolean,
    backgroundcolor?: string,
    textcolor?: string,
    iconcolor?: string
  }>`
  box-shadow: 0px 0px 12px 0px #0000001A;
  border-radius: 8px;
  padding: 16px;
  position: fixed;
  bottom: 40px;
  height: fit-content;
  z-index: 9;
  width: calc(100vw - ${props => props.islogin ? props.ispin ? '368px' : '136px' : '0px' });
  right: 24px;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  background: ${ props => props.backgroundcolor };
  color: ${ props => props.textcolor };
  fill: ${ props => props.iconcolor };
`

const CreditDepositBottomNotify = ({ isPin, isLogin, ...props }: CreditDepositBottomNotifyProps) => {
  const { display, settings, onclose } = useStatusMap()

  return (
    <>
      { display && 
        <StyleWrapper
          backgroundcolor={settings.backgroundColor}
          textcolor={settings.textColor}
          iconcolor={settings.iconColor}
          ispin={isPin}
          islogin={isLogin}
        >
          <SFSVG src={ErrorIcon} width='24px' mr="8px" />
          <div style={{ flexGrow: 1 }}>{settings.message}</div>
          {
            settings.closeable &&
              <SFSVG
                src={CloseIcon}
                width='24px'
                ml="8px"
                style={{ cursor: 'pointer' }}
                onClick={ () => onclose() }
              />
          }
        </StyleWrapper>
      }
    </>
  )
}

export default CreditDepositBottomNotify