import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 取得api-key列表
export const GetApiKeyListService = (): ApiService<ApiKey.GetList.Res> => {
  return request.get('/api_keys')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetApiKeyListService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得單筆api-key詳細
export const GetApiKeyService = (id: string): ApiService<Common.ApiKey> => {
  return request.get(`/api_keys/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetApiKeyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得 Permission 列表
export const GetPermissionService = (): ApiService<ApiKey.GetPermissions.Res> => {
  return request.get('/broker_permissions')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetPermissionService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得 Subscriptions 列表
export const GetSubscriptionsService = (): ApiService<ApiKey.GetSubscriptions.Res> => {
  return request.get('/subscriptions')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetSubscriptionsService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 新增api key
export const PostApiKeyService = (query: ApiKey.PostApiKey.Req): ApiService<ApiKey.PostApiKey.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.post('/api_keys', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostApiKeyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新api key
export const PutApiKeyService = (id: string, query: ApiKey.PutApiKey.Req): ApiService<ApiKey.PutApiKey.Res> => {
  const body = toUnderline(deepCopy(query))
  return request.put(`/api_keys/${id}`, body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PutApiKeyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 刪除api key
export const DelApiKeyService = (id: string): ApiService<boolean> => {
  return request.delete(`/api_keys/${id}`)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('DelApiKeyService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

