import { useMemo, useState } from 'react'

interface Loader {
  isLoading: boolean
  load: () => void
  unload: () => void
}

// 全局加載器
export let loader = {} as Loader
export const useGlobalLoading = () => {
  const [ count, setCount ] = useState(0)
  const isLoading = useMemo(() => count !== 0, [count])

  const load = () => setCount(state => ++state)
  const unload = () => setCount(state => --state)

  loader = {
    isLoading,
    load,
    unload,
  }
}


// 各別加載器
const useLoading = () => {
  const [ count, setCount ] = useState(0)
  const isLoading = useMemo(() => count !== 0, [count])

  const load = () => setCount(state => ++state)
  const unload = () => {
    setCount(state => state > 0 ? --state : 0)
  }
  return {
    isLoading,
    load,
    unload,
  }
}

export default useLoading
