import axios, { AxiosInstance } from 'axios'
import { showMessage } from '@/hooks/useMessage'
import { isDev } from '@otsofintech/sofinx-ui/lib/helper'
import { errorCodeMap } from '@otsofintech/sofinx-ui/lib/data'
import i18n from '@/assets/locales/i18n'
import errorConfig from './error.config'

export type Error = {
  code: keyof typeof errorCodeMap,
  message: string,
  details?: string[],
}

export type ApiService<T, U = Error> = Promise<
  { readonly isError: false; readonly value: T } |
  { readonly isError: true; readonly value: U }
>

type Environment = 'test' | 'develop'

// api url
const proxyRouteMap = {
  test: '/local-proxy-api-test',
  develop: '/local-proxy-api-develop',
}
const proxyRoute = proxyRouteMap[process.env.REACT_APP_MODE as Environment] || 'proxy-error'
const productionHost = document.getElementsByName('api-host')[0]?.getAttribute('content') || ''
const productionRoute = `https://${productionHost}`

const BASE_URL = isDev() ? proxyRoute : productionRoute

// site token
const developSiteToken = process.env.REACT_APP_SITE_TOKEN
const productionSiteToken = document.getElementsByName('site-token')[0]?.getAttribute('content')
const SITE_TOKEN = isDev() ? developSiteToken : productionSiteToken

axios.defaults.headers.common['Authorization'] = SITE_TOKEN

// 解決safari 本地開發無法登入
if (isDev() && navigator.vendor === 'Apple Computer, Inc.') axios.defaults.headers.common['Authentication-Info'] = process.env.REACT_APP_SOFINX_USERID

export const request = axios.create({ baseURL: BASE_URL, withCredentials: true })

export function apiErrorMsg (serviceName: string, error: Error) {
  if (isDev()) console.log(serviceName)

  const config = errorConfig[error?.code as keyof typeof errorConfig]

  let customMsg = ''
  const defaultMsg = i18n.t(errorCodeMap[error?.code || ''] || 'apiError')

  /// config查看是否有針對此error code做個別處理
  if(config) {
    /// 處理 error msg
    if(config.getMessage) customMsg = config.getMessage(error)
  }


  showMessage({ icon: 'error', message: customMsg|| defaultMsg })
}

// Zion 要求測試用
if (isDev('broker-admin.sofinx.otso-dev.com')) (window as typeof window & { axios: AxiosInstance }).axios = request
