import { createSlice } from '@reduxjs/toolkit'
import { UserAuth } from '@/utils/enum'

const authSlice = createSlice({
  name: 'auth',
  initialState: UserAuth.GUEST,
  reducers: {
    setAuth: (state, action: { payload: UserAuth }) => {
      return action.payload
    },
    clearAuth: () => {
      return UserAuth.GUEST
    }
  }
})

export const authActions = authSlice.actions
export default authSlice.reducer