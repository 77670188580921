import { clearAuth, clearBrokerPermission, clearBrokerSubscriptions, clearCompanyConfig, clearConfigSymbol, clearUserInfo, clearUserRolePermission, setAuth, setBrokerSubscriptions, setUserInfo, setUserRolePermission, updateAuditCount, updateBrokerPermission, updateCompanyConfig, updateConfigSymbol, updateCreditDeposit } from '@/store/actions'
import { UserAuth } from '@/utils/enum'
import { useDispatch } from 'react-redux'
import { GetRolesUserSelf } from '@/services/role'
import { selectUserPermissions } from '@/store/slices/userRolePermissionReducer'
import { useBrokerPermission } from './useBrokerPermission'
import { GetSubscriptionsService } from '@/services/api-key'


const useInitData = () => {
  const dispatch = useDispatch()

  const { checkAuth } = useBrokerPermission()

  const setLoginData = async (userInfo: Common.User) => {

    const init = new Promise<boolean>(async (resolve, reject) => {
      dispatch(setAuth(UserAuth.MEMBER))

      /// 取得登入帳號資訊
      dispatch(setUserInfo(userInfo))

      /// 取得完整完整訂閱列表
      const subsriptionsResult = await GetSubscriptionsService()
      if(!subsriptionsResult.isError && subsriptionsResult.value) {
        dispatch(setBrokerSubscriptions(subsriptionsResult.value.subscriptions))
      }

      /// 取得登入帳號角色權限
      const userRolePermissionResult = await GetRolesUserSelf()
      if(!userRolePermissionResult.isError && userRolePermissionResult.value) {
        const activePermissions = selectUserPermissions(userRolePermissionResult.value.roleUser)

        dispatch(setUserRolePermission(activePermissions))

        /// broker資訊
        if(checkAuth({key: 'init-update_company_info', authType: 'read', permissions: activePermissions}))
          dispatch(updateCompanyConfig())

        /// 待審核個數
        if(checkAuth({key: 'init-update_audit_count', authType: 'read', permissions: activePermissions}))
          dispatch(updateAuditCount())

        /// 利潤保證金
        if(checkAuth({key: 'init-update_credit_deposit', authType: 'read', permissions: activePermissions}))
          dispatch(updateCreditDeposit())

        /// symbol 清單
        if(checkAuth({key: 'init-update_config_symbol', authType: 'read', permissions: activePermissions}))
          dispatch(updateConfigSymbol())

        /// 取得完整permission列表
        if(checkAuth({key: 'init-update_broker_permission', authType: 'read', permissions: activePermissions}))
          dispatch(updateBrokerPermission())
      }

      resolve(true)
    })
    
    return init
  }

  const clearLogoutData = () => {
    dispatch(clearAuth())
    dispatch(clearUserInfo())
    dispatch(clearUserRolePermission())
    dispatch(clearCompanyConfig())
    dispatch(clearConfigSymbol())
    dispatch(clearBrokerPermission())
    dispatch(clearBrokerSubscriptions())
  }

  return { setLoginData, clearLogoutData }
}


export default useInitData