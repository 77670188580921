import { toCamel } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 取使用者資訊
export const GetUserService = (): ApiService<Common.User> => {
  return request.get('/user')
    .then(res => ({ isError: false, value: toCamel(res.data.user) }))
    .catch(err => {
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更改使用者語系
export const PutUserLanguageService = (lang: string): ApiService<boolean> => {
  const params = { language: lang }
  return request.put('/user/language', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('PutUserLanguageService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
