import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetPermissionService } from '@/services/api-key'

const initPermission: Common.ApiKeyPermission[] = []

const updateBrokerPermission = createAsyncThunk('config/broker-permission', async () => {
  const result = await GetPermissionService()
  return result.isError === false ? result.value?.permissions || initPermission : initPermission
})

const brokerPermissionSlice = createSlice({
  name: 'broker-permission',
  initialState: initPermission,
  reducers: {
    clearBrokerPermission: () => initPermission
  },
  extraReducers: (builder) => {
    builder.addCase(updateBrokerPermission.fulfilled, (state, action) => action.payload as Common.ApiKeyPermission[])
  }
})

export const brokerPermissionActions = { ...brokerPermissionSlice.actions, updateBrokerPermission}
export default brokerPermissionSlice.reducer
