import { createSlice } from '@reduxjs/toolkit'
import { UserInfo } from '@/utils/class'

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState: UserInfo(),
  reducers: {
    setUserInfo: (state, action: { payload: Common.User }) => {
      return UserInfo(action.payload)
    },
    clearUserInfo: (state) => {
      return UserInfo()
    }
  }
})

export const userinfoActions = userInfoSlice.actions
export default userInfoSlice.reducer
