import { RootState } from '@/store'
import { toLocalFormat } from '@otsofintech/sofinx-ui/lib/helper'
import { theme } from '@otsofintech/sofinx-ui/lib/style'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

type ConfigType = {
  show: boolean
  settings?: {
    message: string
    closeable: boolean
    backgroundColor: string
    textColor: string
    iconColor: string
  }
}

const useStatusMap = () => {
  
  const { t, i18n } = useTranslation()

  const { status, depositDeadline } = useSelector((state: RootState) => state.creditDeposit)

  const [closed, setclosed] = useState(false)

  /// 關閉提示
  const onclose = () => setclosed(true)

  const config: {[key: string]: ConfigType } = useMemo(() => ({
    NORMAL: {
      show: false,
    },
    NOTICE: {
      show: true,
      settings: {
        message: t('creditNoticeNotify'),
        closeable: true,
        backgroundColor: '#FFF5E0',
        textColor: theme.palette.warn1,
        iconColor: theme.palette.warn1,
      }
    },
    WARNING: {
      show: true,
      settings: {
        message: t('creditWraningNotify'),
        closeable: true,
        backgroundColor: '#FFF5E0',
        textColor: theme.palette.warn1,
        iconColor: theme.palette.warn1,
      }
    },
    LOCKOUT: {
      show: true,
      settings: {
        message: t('creditLockoutNotify'),
        closeable: false,
        backgroundColor: '#FFF1F1',
        textColor: theme.palette.error2,
        iconColor: theme.palette.error2,
      }
    },
    STOPPAGE: {
      show: true,
      settings: {
        message: t('creditStoppageNotify', { deadline: toLocalFormat({ value: depositDeadline || '', to: 'YYYY-MM-DD HH:mm' })  }),
        closeable: false,
        backgroundColor: '#FFF1F1',
        textColor: theme.palette.error2,
        iconColor: theme.palette.error2,
      }
    },
  }), [status, i18n.languages])

  const display = useMemo(() => config[status]?.show && !closed, [status, closed])

  return({
    display,
    settings: {
      message: config[status]?.settings?.message,
      closeable: config[status]?.settings?.closeable,
      backgroundColor: config[status]?.settings?.backgroundColor,
      textColor: config[status]?.settings?.textColor,
      iconColor: config[status]?.settings?.iconColor,
    },
    onclose,
  })
}

export default useStatusMap
