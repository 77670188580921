import { lazy, Suspense, useCallback } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import Layout from '@/pages/layout'
import { SubscriptionItems, UserAuth } from '@/utils/enum'
import AuthRoute from './AuthRoute'
import Loading from './loading'
import _without from 'lodash/without'
import { RoleAuthKeys } from '@/utils/enum'
import { useBrokerSubscriptions } from '@/hooks/useBrokerSubscription'
import { isSofinqDomain } from '@/utils/helper'


const NotFound = lazy(() => import(/* webpackChunkName: "not-found" */ '@otsofintech/sofinx-ui/lib/components/SFErrorNotFound'))
const Login = lazy(() => import(/* webpackChunkName: "login" */ '@/pages/login'))
const AccessDenied = lazy(() => import(/* webpackChunkName: "access-denied" */ '@/pages/access-denied'))

// 使用者選單
const Home = lazy(() => import(/* webpackChunkName: "home" */ '@/pages/home'))
const CreditDeposit = lazy(() => import(/* webpackChunkName: "credit-deposit" */ '@/pages/credit-deposit'))
const SystemLog = lazy(() => import(/* webpackChunkName: "system-log" */ '@/pages/system-log'))
const ApiManagement = lazy(() => import(/* webpackChunkName: "api-management" */ '@/pages/api-management'))
const Authority = lazy(() => import(/* webpackChunkName: "authority" */ '@/pages/authority'))

// 設置
const CompanySetting = lazy(() => import(/* webpackChunkName: "company-setting" */ '@/pages/company-setting'))
const ManageDeposit = lazy(() => import(/* webpackChunkName: "manage-deposit" */ '@/pages/manage-deposit'))
const ManageDepositWireCreate = lazy(() => import(/* webpackChunkName: "manage-deposit-wire-create" */ '@/pages/manage-deposit-wire'))
const ManageDepositWireEdit = lazy(() => import(/* webpackChunkName: "manage-deposit-wire-edit" */ '@/pages/manage-deposit-wire/_id'))
const ManageDepositCryptoCreate = lazy(() => import(/* webpackChunkName: "manage-deposit-crypto-create" */ '@/pages/manage-deposit-crypto'))
const ManageDepositCryptoEdit = lazy(() => import(/* webpackChunkName: "manage-deposit-crypto-edit" */ '@/pages/manage-deposit-crypto/_id'))
const ManageDepositHdWalletCreate = lazy(() => import(/* webpackChunkName: "manage-deposit-hdwallet-create" */ '@/pages/manage-deposit-hdwallet'))
const ManageDepositHdWalletEdit = lazy(() => import(/* webpackChunkName: "manage-deposit-hdwallet-edit" */ '@/pages/manage-deposit-hdwallet/_id'))
const ManageDepositPaymentChannelCreate = lazy(() => import(/* webpackChunkName: "manage-deposit-payment-channel-create" */ '@/pages/manage-deposit-payment-channel'))
const ManageDepositPaymentChannelEdit = lazy(() => import(/* webpackChunkName: "manage-deposit-payment-channel-edit" */ '@/pages/manage-deposit-payment-channel/_id'))
const ManageWithdraw = lazy(() => import(/* webpackChunkName: "manage-withdraw" */ '@/pages/manage-withdraw'))
const ManageWithdrawWireCreate = lazy(() => import(/* webpackChunkName: "manage-withdraw-wire-create" */ '@/pages/manage-withdraw-wire'))
const ManageWithdrawWireEdit = lazy(() => import(/* webpackChunkName: "manage-withdraw-wire-edit" */ '@/pages/manage-withdraw-wire/_id'))
const ManageWithdrawCryptoCreate = lazy(() => import(/* webpackChunkName: "manage-withdraw-crypto-create" */ '@/pages/manage-withdraw-crypto'))
const ManageWithdrawCryptoEdit = lazy(() => import(/* webpackChunkName: "manage-withdraw-crypto-edit" */ '@/pages/manage-withdraw-crypto/_id'))
const ManageIdentityVerification = lazy(() => import(/* webpackChunkName: "manage-identity-verification" */ '@/pages/manage-identity-verification'))
const ManagePlatformSettings = lazy(() => import(/* webpackChunkName: "manage-platform-settings" */ '@/pages/manage-platform-settings'))
// 流動性
const Liquidity = lazy(() => import(/* webpackChunkName: "liquidity" */ '@/pages/liquidity'))
const LiquidityDetail = lazy(() => import(/* webpackChunkName: "liquidity-detail" */ '@/pages/liquidity-detail'))
// 投資者
const Investor = lazy(() => import(/* webpackChunkName: "investor" */ '@/pages/investor'))
const InvestorImport = lazy(() => import(/* webpackChunkName: "investor" */ '@/pages/investor-import'))
const InvestorDetail = lazy(() => import(/* webpackChunkName: "investor-detail" */ '@/pages/investor-detail'))
const InvestorKycDetails = lazy(() => import(/* webpackChunkName: "investor-detail-overview-kyc-details" */ '@/pages/investor-detail-overview/kyc-details'))
// 訊號源管理
const Signal = lazy(() => import(/* webpackChunkName: 'signal' */'@/pages/signal'))
const SignalDetail = lazy(() => import(/* webpackChunkName: 'signal-detail' */'@/pages/signal-detail'))
// 交易品種管理
const Symbol = lazy(() => import(/* webpackChunkName: "symbol" */ '@/pages/symbol'))
// 組別與規則設置
const RuleGroup = lazy(() => import(/* webpackChunkName: "rule-group" */ '@/pages/rule-group'))
const RuleSymbol = lazy(() => import(/* webpackChunkName: "rule-symbol" */ '@/pages/rule-symbol'))
const RuleSymbolDetail = lazy(() => import(/* webpackChunkName: "rule-symbol-detail" */ '@/pages/rule-symbol-detail'))
const RuleLiquidity = lazy(() => import(/* webpackChunkName: "rule-liquidity" */ '@/pages/rule-liquidity'))
const RuleLiquidityDetail = lazy(() => import(/* webpackChunkName: "rule-liquidity-detail" */ '@/pages/rule-liquidity-detail'))
const RuleInterest = lazy(() => import(/* webpackChunkName: "rule-interest" */ '@/pages/rule-interest'))
const RuleInterestDetail = lazy(() => import(/* webpackChunkName: "rule-interest-detail" */ '@/pages/rule-interest-detail'))
const RuleInterestDetailImport = lazy(() => import(/* webpackChunkName: "rule-interest-detail-import" */ '@/pages/rule-interest-detail-import'))
const RuleFee = lazy(() => import(/* webpackChunkName: "rule-fee" */ '@/pages/rule-fee'))
const RuleFeeDetail = lazy(() => import(/* webpackChunkName: "rule-fee-detail" */ '@/pages/rule-fee-detail'))
const RuleFeeDetailImport = lazy(() => import(/* webpackChunkName: "rule-fee-detail-import" */ '@/pages/rule-fee-detail-import'))

// 比賽管理
const Competition = lazy(() => import(/* webpackChunkName: "competition" */ '@/pages/competition'))
const CompetitionDetail = lazy(() => import(/* webpackChunkName: "competition-detail" */ '@/pages/competition-detail'))
const CompetitionCreate = lazy(() => import(/* webpackChunkName: "competition-create" */ '@/pages/competition-create'))
const CompetitionEdit = lazy(() => import(/* webpackChunkName: "competition-edit" */ '@/pages/competition-edit'))
// 報表
const ReportSymbol = lazy(() => import(/* webpackChunkName: "report-symbol" */ '@/pages/report-symbol'))
const ReportPosition = lazy(() => import(/* webpackChunkName: "report-position" */ '@/pages/report-position'))
const ReportClosedTrades = lazy(() => import(/* webpackChunkName: "report-closed-trades" */ '@/pages/report-closed-trades'))
const ReportInvestorProfit = lazy(() => import(/* webpackChunkName: "report-investor-profit" */ '@/pages/report-investor-profit'))
const ReportOrder = lazy(() => import(/* webpackChunkName: "report-order" */ '@/pages/report-order'))
// 審核
const AuditDeposit = lazy(() => import(/* webpackChunkName: "audit-deposit" */ '@/pages/audit-deposit'))
const AuditWithdraw = lazy(() => import(/* webpackChunkName: "audit-withdraw" */ '@/pages/audit-withdraw'))
const IdentityVerificationReview = lazy(() => import(/* webpackChunkName: "review-identity-verification" */ '@/pages/review-identity-verification'))
const KycDetailsRequest = lazy(() => import(/* webpackChunkName: "request-identity-verification-id" */ '@/pages/review-identity-verification/_id'))

// 佈告欄
const Announcement = lazy(() => import(/* webpackChunkName: "announcement" */ '@/pages/announcement'))
const AnnouncementDetail = lazy(() => import(/* webpackChunkName: "AnnouncementDetail" */ '@/pages/announcement/_id'))

const authMap = {
  guest: [UserAuth.GUEST],
  member: [UserAuth.MEMBER],
  public: [UserAuth.GUEST, UserAuth.MEMBER],
}

const Router = () => {
  // 權限
  const auth = useSelector((state: RootState) => state.auth)
  const userRolePermissions = useSelector((state: RootState) => state.userRolePermission)
  const { checkSubscription } = useBrokerSubscriptions()

  const checkAuth = useCallback((authList: UserAuth[]) => {
    return authList.length === 0 || authList.includes(auth)
  }, [auth])

  const hasPermission = (permissions: string[]) => {
    if (_without(permissions, ...userRolePermissions).length) return false
    return true
  }

  return (
    <BrowserRouter>
      <Loading />
      <Layout>
        <Suspense fallback={null}>
          <Switch>
            <AuthRoute path="/login" component={Login} auth={checkAuth(authMap.guest)} to="/" />

            <AuthRoute exact path="/" component={Home} auth={checkAuth(authMap.member)} />
            <AuthRoute exact path="/credit-deposit" component={CreditDeposit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.TrustFundDataRead])} />
            <AuthRoute exact path="/system-log" component={SystemLog} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.LoggingDataRead])} />
            <AuthRoute exact path="/Api-management" component={ApiManagement} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ApiKeyDataRead])} />
            <AuthRoute exact path="/authority" component={Authority} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.StaffDataRead])} />

            <AuthRoute exact path="/setting/company" component={CompanySetting} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.BrokerSettingRead])} />
            <AuthRoute exact path="/setting/deposit" component={ManageDeposit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/wire" component={ManageDepositWireCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/wire/:id" component={ManageDepositWireEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/crypto" component={ManageDepositCryptoCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/crypto/:id" component={ManageDepositCryptoEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/hdwallet" component={ManageDepositHdWalletCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/hdwallet/:id" component={ManageDepositHdWalletEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/payment-channel" component={ManageDepositPaymentChannelCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/deposit/payment-channel/:id" component={ManageDepositPaymentChannelEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/withdraw" component={ManageWithdraw} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/withdraw/wire" component={ManageWithdrawWireCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/withdraw/wire/:id" component={ManageWithdrawWireEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/withdraw/crypto" component={ManageWithdrawCryptoCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/withdraw/crypto/:id" component={ManageWithdrawCryptoEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/setting/identity-verification" component={ManageIdentityVerification} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.IdentityVerificationSettingRead])} hasSubscription={checkSubscription(SubscriptionItems.IdentityVerification)} />
            <AuthRoute exact path="/setting/platform-settings" auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.MerchantProfitShareRead])} component={ManagePlatformSettings} />

            <AuthRoute exact path="/liquidity" component={Liquidity} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.LiquidityRead])} />
            <AuthRoute exact path="/liquidity/:id" component={LiquidityDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.LiquidityRead])} />

            <AuthRoute exact path="/investor" component={Investor} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.InvestorDataRead])} />
            <AuthRoute exact path="/investor/import" component={InvestorImport} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.InvestorDataRead])} />
            <AuthRoute exact path="/investor/:id" component={InvestorDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.InvestorDataRead])} />
            <AuthRoute exact path="/investor/:id/:type" component={InvestorDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.InvestorDataRead])} />
            <AuthRoute exact path="/investor/:id/overview/kyc-detail" component={InvestorKycDetails} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.InvestorDataRead])} />

            <AuthRoute exact path="/signal" component={Signal} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SignalDataRead])} />
            <AuthRoute exact path="/signal/:id" component={SignalDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SignalDataRead])} />

            <AuthRoute exact path="/symbol" component={Symbol} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SymbolDataRead])} />

            <AuthRoute exact path="/rule/group" component={RuleGroup} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.GroupDataRead])} />
            <AuthRoute exact path="/rule/symbol" component={RuleSymbol} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SymbolRuleDataRead])} />
            <AuthRoute exact path="/rule/symbol/:id" component={RuleSymbolDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SymbolRuleDataRead])} />
            <AuthRoute exact path="/rule/liquidity" component={RuleLiquidity} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.LiquidityRuleDataRead])} />
            <AuthRoute exact path="/rule/liquidity/:id" component={RuleLiquidityDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.LiquidityRuleDataRead])} />
            <AuthRoute exact path="/rule/interest" component={RuleInterest} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SwapRuleDataRead])} />
            <AuthRoute exact path="/rule/interest/:id" component={RuleInterestDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SwapRuleDataRead])} />
            <AuthRoute exact path="/rule/interest/:id/import" component={RuleInterestDetailImport} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.SwapRuleDataRead])} />
            <AuthRoute exact path="/rule/fee" component={RuleFee} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.FeeRuleDataRead])} />
            <AuthRoute exact path="/rule/fee/:id" component={RuleFeeDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.FeeRuleDataRead])} />
            <AuthRoute exact path="/rule/fee/:id/import" component={RuleFeeDetailImport} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.FeeRuleDataRead])} />

            <AuthRoute exact path="/competition" component={Competition} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.CompetitionDataRead])} />
            <AuthRoute exact path="/competition/create" component={CompetitionCreate} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.CompetitionDataRead])} />
            <AuthRoute exact path="/competition/:id/edit" component={CompetitionEdit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.CompetitionDataRead])} />
            <AuthRoute exact path="/competition/:id" component={CompetitionDetail} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.CompetitionDataRead])} />

            <AuthRoute exact path="/report/symbol" component={ReportSymbol} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ReportDataRead])} />
            <AuthRoute exact path="/report/position" component={ReportPosition} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ReportDataRead])} />
            <AuthRoute exact path="/report/closed-trades" component={ReportClosedTrades} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ReportDataRead])} />
            <AuthRoute exact path="/report/investor-profit" component={ReportInvestorProfit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ReportDataRead])} />
            <AuthRoute exact path="/report/order" component={ReportOrder} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.ReportDataRead])} />

            <AuthRoute exact path="/audit/deposit" component={AuditDeposit} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/audit/withdraw" component={AuditWithdraw} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.PaymentDataRead])} />
            <AuthRoute exact path="/audit/identity-verification" component={IdentityVerificationReview} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.IdentityVerificationRequestRead])} hasSubscription={checkSubscription(SubscriptionItems.IdentityVerification)} />
            <AuthRoute exact path="/audit/identity-verification/:id" component={KycDetailsRequest} auth={checkAuth(authMap.member)} hasPermission={hasPermission([RoleAuthKeys.IdentityVerificationRequestRead])} hasSubscription={checkSubscription(SubscriptionItems.IdentityVerification)} />


            {isSofinqDomain() && <AuthRoute exact path="/announcement-setting" component={Announcement} auth={checkAuth(authMap.member)} />}
            {isSofinqDomain() && <AuthRoute exact path="/announcement-setting/edit" component={AnnouncementDetail} auth={checkAuth(authMap.member)} />}
            {isSofinqDomain() && <AuthRoute exact path="/announcement-setting/edit/:id" component={AnnouncementDetail} auth={checkAuth(authMap.member)} />}


            <AuthRoute exact path="/access-denied" component={AccessDenied} auth={checkAuth(authMap.public)} />

            <AuthRoute path="*" component={NotFound} auth={checkAuth(authMap.public)} />
          </Switch>
        </Suspense>
      </Layout>
    </BrowserRouter>
  )
}

export default Router
