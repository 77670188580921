import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { SFTooltip, SFOperateButton } from '@otsofintech/sofinx-ui'
import I18nSelector from '@/components/i18n-selector'
import { LogoutService } from '@/services/auth'
import Logout from '@/assets/images/logout.svg'
import useInitData from '@/hooks/useInitData'

const Footer = styled.footer`
  padding: 0 20px 20px;
  height: 80px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`

const ContentFooter = () => {
  const { t } = useTranslation()
  const { clearLogoutData } = useInitData()

  const logout = async () => {
    const result = await LogoutService()
    if (result.isError === false) {
      clearLogoutData()
    }
  }

  return (
    <Footer className='footer'>
      <I18nSelector />
      <SFTooltip title={t('logout')}>
        <div>
          <SFOperateButton src={Logout} onClick={logout} />
        </div>
      </SFTooltip>
    </Footer>
  )
}

export default ContentFooter
