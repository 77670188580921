import ReactDOM from 'react-dom'
import store from '@/store'
import { Provider } from 'react-redux'
import { StylesProvider } from '@material-ui/core/styles'
import App from './App'

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider injectFirst>
      <App />
    </StylesProvider>
  </Provider>,
  document.getElementById('root'),
)
