import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetConfigSymbolService } from '@/services/config'

const initSymbols: Common.Symbol[] = []

const updateConfigSymbol = createAsyncThunk('config/symbol', async () => {
  const result = await GetConfigSymbolService()
  return result.isError === false ? result.value?.symbols || initSymbols : initSymbols
})

const symbolsSlice = createSlice({
  name: 'symbols',
  initialState: initSymbols,
  reducers: {
    clearConfigSymbol: () => initSymbols
  },
  extraReducers: (builder) => {
    builder.addCase(updateConfigSymbol.fulfilled, (state, action) => action.payload as Common.Symbol[])
  }
})

export const symbolsActions = { ...symbolsSlice.actions, updateConfigSymbol}
export default symbolsSlice.reducer
