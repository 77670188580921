/**
 * Guest: 未登入 |
 * Member: 登入(權限1)
 */
export enum UserAuth {
  GUEST = 'GUEST',
  MEMBER = 'MEMBER',
  ADMIN = 'ADMIN',
}

/**
 * 真實資金紀錄類型
 */
export enum RealWalletRecordType {
  DEPOSIT = 'DEPOSIT',
  DEPOSIT_WIRE = 'DEPOSIT_WIRE',
  DEPOSIT_PAYMENT = 'DEPOSIT_PAYMENT',
  DEPOSIT_CRYPTO = 'DEPOSIT_CRYPTO',
  DEPOSIT_HDWALLET = 'DEPOSIT_HDWALLET',
  WITHDRAW_WIRE = 'WITHDRAW_WIRE',
  WITHDRAW_CRYPTO = 'WITHDRAW_CRYPTO',
  ROBOT_START = 'ROBOT_START',
  ROBOT_UPDATE = 'ROBOT_UPDATE',
  ROBOT_STOP = 'ROBOT_STOP',
  ROBOT_PROFIT_SHARE = 'ROBOT_PROFIT_SHARE',
  COMPETITION_JOINED = 'COMPETITION_JOINED',
  COMPETITION_TRANSFER = 'COMPETITION_TRANSFER',
  COMPETITION_REWARD = 'COMPETITION_REWARD',
  DEPOSIT_REAL_BALANCE_BY_BROKER = 'DEPOSIT_REAL_BALANCE_BY_BROKER',
  WITHDRAW_REAL_BALANCE_BY_BROKER = 'WITHDRAW_REAL_BALANCE_BY_BROKER',
  WITHDRAW_TOKEN_BY_BROKER = 'WITHDRAW_TOKEN_BY_BROKER'
}

// 託管資金狀態
export enum CreditDepositStatus {
  NORMAL = 'NORMAL',
  NOTICE = 'NOTICE',
  WARNING = 'WARNING',
  LOCKOUT = 'LOCKOUT',
  STOPPAGE = 'STOPPAGE'
}


export enum RoleAuthKeys {
  /// 讀取投資人資料
  InvestorDataRead = 'InvestorDataRead',
  /// 修改投資人資料
  InvestorDataWrite = 'InvestorDataWrite',
  /// 讀取出入金資料
  PaymentDataRead = 'PaymentDataRead',
  /// 寫入出入金資料
  PaymentDataWrite = 'PaymentDataWrite',
  /// 讀取報表資料
  ReportDataRead = 'ReportDataRead',
  /// 交易組資料讀取
  GroupDataRead = 'GroupDataRead',
  /// 交易組資料寫入
  GroupDataWrite = 'GroupDataWrite',
  /// 交易品種規則讀取
  SymbolRuleDataRead = 'SymbolRuleDataRead',
  /// 交易品種規則寫入
  SymbolRuleDataWrite = 'SymbolRuleDataWrite',
  /// 流動性規則讀取
  LiquidityRuleDataRead = 'LiquidityRuleDataRead',
  /// 流動性規則寫入
  LiquidityRuleDataWrite = 'LiquidityRuleDataWrite',
  /// 利息規則讀取
  SwapRuleDataRead = 'SwapRuleDataRead',
  /// 利息規則寫入
  SwapRuleDataWrite = 'SwapRuleDataWrite',
  /// 手續費規則讀取
  FeeRuleDataRead = 'FeeRuleDataRead',
  /// 手續費規則寫入
  FeeRuleDataWrite = 'FeeRuleDataWrite',
  /// 讀取公司資料
  BrokerSettingRead = 'BrokerSettingRead',
  /// 寫入公司資料
  BrokerSettingWrite = 'BrokerSettingWrite',
  /// 讀取API Key資料
  ApiKeyDataRead = 'ApiKeyDataRead',
  /// 寫入API Key資料
  ApiKeyDataWrite = 'ApiKeyDataWrite',
  /// 讀取利潤保證金
  TrustFundDataRead = 'TrustFundDataRead',
  /// 讀取流動性資料
  LiquidityRead = 'LiquidityRead',
  /// 讀取訊號源資料
  SignalDataRead = 'SignalDataRead',
  /// 寫入訊號源資料
  SignalDataWrite = 'SignalDataWrite',
  /// 讀取系統日誌資料
  LoggingDataRead = 'LoggingDataRead',
  /// 讀取交易品種資料
  SymbolDataRead = 'SymbolDataRead',
  /// 修改交易品種資料
  SymbolDataWrite = 'SymbolDataWrite',
  /// 讀取比賽資料
  CompetitionDataRead = 'CompetitionDataRead',
  /// 修改比賽資料
  CompetitionDataWrite = 'CompetitionDataWrite',
  /// 讀取角色與權限
  StaffDataRead = 'StaffDataRead',
  /// 寫入角色與權限
  StaffDataWrite = 'StaffDataWrite',
  /// 讀取身份驗證設置資料
  IdentityVerificationSettingRead = 'IdentityVerificationSettingRead',
  /// 寫入身份驗證設置資料
  IdentityVerificationSettingWrite = 'IdentityVerificationSettingWrite',
  /// 讀取身份驗證審核資料
  IdentityVerificationRequestRead = 'IdentityVerificationRequestRead',
  /// 寫入身份驗證審核資料
  IdentityVerificationRequestWrite = 'IdentityVerificationRequestWrite',
  /// 讀取Merchant利潤分成資料
  MerchantProfitShareRead = 'MerchantProfitShareRead',
  /// 寫入Merchant利潤分成資料
  MerchantProfitShareWrite = 'MerchantProfitShareWrite',
  /// 寫入通知權限
  AnnouncementWrite = 'AnnouncementWrite'
}

export enum RoleAuthGroup {
  broker = 'broker',
  investor = 'investor',
  payment = 'payment',
  report = 'report',
  groupAndRules = 'groupAndRules',
  groupAndRulesGroup = 'groupAndRulesGroup',
  groupAndRulesSymbolRule = 'groupAndRulesSymbolRule',
  groupAndRulesLiquidityRule = 'groupAndRulesLiquidityRule',
  groupAndRulesSwapRule = 'groupAndRulesSwapRule',
  groupAndRulesFeeRule = 'groupAndRulesFeeRule',
  apiKey = 'apiKey',
  trustFunds = 'trustFunds',
  liquidity = 'liquidity',
  signal = 'signal',
  logging = 'logging',
  symbol = 'symbol',
  competition = 'competition',
  staff = 'staff',
  identityVerification = 'identityVerification',
  identityVerificationSetting = 'identityVerificationSetting',
  identityVerificationRequest = 'identityVerificationRequest',
  merchantProfitShare = 'merchantProfitShare',
  announcement = 'announcement'
}


export enum SubscriptionItems {
  IdentityVerification = 'IDENTITY_VERIFICATION'
}

export enum CompetitionDateSettingErrorType { beginTime = 'beginTime', timeRange = 'timeRange', required = 'required' }

/**
 * 交易平台
 */
export enum Platform {
  MT4 = 'MT4',
  MT5 = 'MT5',
  BINANCE = 'BINANCE',
}
/**
 * 圖片上傳狀態
 */
export enum UploadStatus { SUCCESS='success',YET='yet',UPLOADING='uploading' }