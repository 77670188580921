import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 取得公司基本資料
export const GetCompanyInfoService = (): ApiService<CompanySetting.GetInfo.Res> => {
  return request.get('/broker/info')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompanyInfoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新公司基本資料
export const UpdateCompanyInfoService = (query: CompanySetting.UpdateInfo.Req): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/broker/info', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('GetCompanyInfoService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得公司圖片資料
export const GetCompanyVisualService = (): ApiService<CompanySetting.GetVisual.Res> => {
  return request.get('/broker/visual')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompanyVisualService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新公司圖片資料
export const UpdateCompanyVisualService = (query: CompanySetting.UpdateVisual.Req): ApiService<boolean> => {
  const formData = new FormData()
  formData.append('change_light_logo', String(query.changeLightLogo))
  formData.append('light_logo_file', query.lightLogoFile)
  formData.append('change_dark_logo', String(query.changeDarkLogo))
  formData.append('dark_logo_file', query.darkLogoFile)
  formData.append('change_favicon', String(query.changeFavicon))
  formData.append('favicon_file', query.faviconFile)

  return request.patch('/broker/visual', formData)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('UpdateCompanyVisualService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 取得公司客製化 Menu
export const GetCompanyCustomizedMenuService = (): ApiService<CompanySetting.GetCustomizedMenu.Res> => {
  return request.get('/broker/customized_menu')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetCompanyCustomizedMenuService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新公司客製化 Menu
export const UpdateCompanyCustomizedMenuService = (query: CompanySetting.updateCustomizedMenu.Req): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.patch('/broker/customized_menu', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('UpdateCompanyCustomizedMenuService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

export const GetMerchantProfitSharePercent = (): ApiService<CompanySetting.GetMerchantProfitSharePercent.Res> => {
  return request.get('broker/merchant_profit_share_percent').then(res => ({isError: false, value: toCamel(res.data)})).catch(err => {
    apiErrorMsg('GetMerchantProfitSharePercent', err.response?.data?.error)
    return { isError: true, value: err.response?.data?.error }
  })
}

export const UpdateMerchantProfitSharePercent = (query: CompanySetting.UpdateMerchantProfitSharePercent.Req): ApiService<CompanySetting.UpdateMerchantProfitSharePercent.Res> => {
  return request.post('broker/merchant_profit_share_percent', toUnderline(query)).then(res => ({isError: false, value: res.data })).catch(err => {
    apiErrorMsg('UpdateMerchantProfitSharePercent', err.response?.data?.error)
    return { isError: true, value: err.response?.data.error }
  })
}