import { errorCodeMap } from '@otsofintech/sofinx-ui/lib/data'
import { Error } from '../api-helper'
import i18n from '@/assets/locales/i18n'


/// 針對 error code 做個別處理
const errorConfig = {
  /// 權限不足
  5040: {
    getMessage: (error: Error) => {
      let i18nkey = ''
      const details = error.details ? error.details[0] : ''

      /// 首字母轉小寫
      if(details) i18nkey = details.replace(/^./, details[0].toLocaleLowerCase())


      return i18n.t(errorCodeMap[5040], { auth: i18n.t(i18nkey) })
    }
  }
}

export default errorConfig