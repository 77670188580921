import { RootState } from '@/store'
import { useSelector } from 'react-redux'

export const useBrokerSubscriptions = () => {
  const borkerSubscriptions = useSelector((state: RootState) => state.borkerSubscription)
  
  const featuresArray = [] as string[]
  for (const item of borkerSubscriptions) {
    if (item.enabled) featuresArray.push(item.feature)
  }

  const checkSubscription = (key: string): boolean => {
    return featuresArray.includes(key)
  }

  return { checkSubscription }
}
