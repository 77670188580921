import { Route, Redirect } from 'react-router-dom'

interface AuthRouteProps {
  path: string
  component: React.LazyExoticComponent<any>
  exact?: true
  auth?: boolean
  to?: string
  hasPermission?: boolean
  hasSubscription?: boolean
}

const AuthRoute = ({path, component: Component, exact, auth = true, hasPermission = true, hasSubscription = true, to }: AuthRouteProps) => {
  const attrs = {
    exact: exact === true,
  }

  const toByStatus = to ? to : auth ? '/access-denied' : '/login'
  return <Route {...attrs} path={path} render={() => auth && hasSubscription && hasPermission ? <Component /> : <Redirect to={toByStatus} /> } />
}

export default AuthRoute
