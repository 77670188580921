import { GetRolesUserSelf } from '@/services/role'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'


export const initState: string[] = []

const updateUserRolePermission = createAsyncThunk('config/user-role-permission', async () => {
  const result = await GetRolesUserSelf()
  return result.isError === false ? selectUserPermissions(result.value.roleUser) || initState : initState
})


/// 取得登入帳號角色
const userRolePermissionSlice = createSlice({
  name: 'userRolePermission',
  initialState: initState,
  reducers: {
    clearUserRolePermission: () => initState,
    setUserRolePermission: (state, action: { payload: string[] }) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserRolePermission.fulfilled, (state, action) => action.payload)
  }
})


export const selectUserPermissions = (roleUser: Common.RoleUser) => {
  const roles = roleUser.roles || []
  const results: string[] = []
  roles.forEach(role => {
    role.brokerRolePermissions.forEach(permission => results.push(permission.name))
  })
  return results
}
export const userRolePermissionActions = { ...userRolePermissionSlice.actions, updateUserRolePermission}
export default userRolePermissionSlice.reducer

