import { createSlice } from '@reduxjs/toolkit'

export type ReportType = 'realFunds' | 'virtualFunds'

const getDefaultState = () => { return { reportType: 'realFunds'} as { reportType: ReportType}}

/**
 * 所有頁面中需要暫存使用者操作的部分
 */
const pageTempSlice = createSlice({
  name: 'pageTemp',
  initialState: getDefaultState(),
  reducers: {
    setReportType: (state, action: { payload: ReportType }) => {
      return { ...state, reportType: action.payload }
    },
    clearPageTemp: (state) => {
      return getDefaultState()
    }
  }
})

export const pageTempSliceActions = pageTempSlice.actions
export default pageTempSlice.reducer
