import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetSubscriptionsService } from '@/services/api-key'

const initSubscription: Common.ApiKeySubscription[] = []

const updateBrokerSubscriptions = createAsyncThunk('config/broker-subscriptions', async () => {
  const result = await GetSubscriptionsService()
  return result.isError === false ? result.value || initSubscription : initSubscription
})

const brokerSubscriptionSlice = createSlice({
  name: 'broker-subscriptions',
  initialState: initSubscription,
  reducers: {
    clearBrokerSubscriptions: () => initSubscription,
    setBrokerSubscriptions: (state, action: { payload: Common.ApiKeySubscription[] }) => {
      return action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBrokerSubscriptions.fulfilled, (state, action) => action.payload as Common.ApiKeySubscription[])
  }
})

export const brokerSubscriptionActions = { ...brokerSubscriptionSlice.actions, updateBrokerSubscriptions}
export default brokerSubscriptionSlice.reducer
