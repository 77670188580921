import styled from 'styled-components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { SFText, SFAutoEllipsis, SFSVG } from '@otsofintech/sofinx-ui'
import UserIcon from '@/assets/images/freeze-color/user.svg'
import FundIcon from '@/assets/images/fund-outline.svg'
import ApiIcon from '@/assets/images/api-management.svg'
import LoginIcon from '@/assets/images/system-log.svg'
import CircleDoubleIcon from '@/assets/images/circle-double.svg'
import DashboardIcon from '@/assets/images/dashboard.svg'
import NoticeIcon from '@/assets/images/error-outline.svg'
import WarningIcon from '@/assets/images/warning.svg'
import LockoutIcon from '@/assets/images/stop.svg'
import WorkOutline from '@/assets/images/work-outline.svg'
import { CreditDepositStatus } from '@/utils/enum'
import PeopleIcon from '@/assets/images/people.svg'
import { useLocation } from 'react-router-dom'
import { useBrokerPermission, AuthKeyType } from '@/hooks/useBrokerPermission'

const IconWrap = styled.div`
  width: 24px;
  height: 24px;
`

const UserDiv = styled.div`
  position: relative;
  margin: 4px 0 12px;
  padding: 0 20px;
  color: ${ props => props.theme.palette.grey60 };
  font-size: 14px;
  font-weight: 600;
  z-index: 100;
`

const UserCard = styled.div<{ $childLength: number }>`
  width: 100%;
  overflow: hidden;
  box-shadow: ${ props => props.theme.shadow[500] };
  border-radius: 6px;
  transition: all .2s ${ props => props.theme.curve.default };
  background: ${ props => props.theme.palette.blank };

  &:hover {
    // 高度: 選項數量 * 42 + 92
    height: ${ props => props.$childLength * 42 + 92 }px !important;
  }

  &:hover .lamp {
    fill: ${ props => props.theme.palette.grey200 };
  }
`

const UserInfo = styled.div<{ ispin: boolean }>`
  display: flex;
  align-items: center;
  height: ${ props => props.ispin ? '42px' : 'initial' };
`

const MenuContent = styled.div`
  margin-top: 16px;
`

const MenuItem = styled(Link)`
  display: flex;
  align-items: center;
  height: 42px;
  padding: 8px;
  border-radius: 4px;
  transition: all .2s ${ props => props.theme.curve.default };

  &:hover {
    background: ${ props => props.theme.palette.grey20 };
  }
  &.current-route {
    background: ${ props => props.theme.palette.grey20 };
  }
`

interface ContentUserProps {
  username: string
  isPin: boolean
}

const fundStatusMap = {
  [CreditDepositStatus.NORMAL]: <></>,
  [CreditDepositStatus.NOTICE]:  <SFSVG ml="auto" src={NoticeIcon} color="warn2" width="16px" height="16px" />,
  [CreditDepositStatus.WARNING]:  <SFSVG ml="auto" src={WarningIcon} color="warn1" width="16px" height="16px" />,
  [CreditDepositStatus.LOCKOUT]:  <SFSVG ml="auto" src={LockoutIcon} color="error2" width="16px" height="16px" />,
}

const ContentUser = ({ username, isPin }: ContentUserProps) => {
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const { checkAuth } = useBrokerPermission()

  const creditDepositPercent = useSelector((state: RootState) => {
    const { profitShareUnsettled, balance } = state.creditDeposit
    return Math.floor(((Number(balance) || 0) - (Number(profitShareUnsettled) || 0)) / (Number(balance) || 0) * 100) || 0
  })

  const creditDepositStatus: CreditDepositStatus = useMemo(() => {
    if (creditDepositPercent >= 51) return CreditDepositStatus.NORMAL
    else if (creditDepositPercent >= 31) return CreditDepositStatus.NOTICE
    else if (creditDepositPercent >= 11) return CreditDepositStatus.WARNING
    else return CreditDepositStatus.LOCKOUT
  }, [creditDepositPercent])


  const menuList = useMemo(() => [
    {
      key: 'dashboard',
      icon: DashboardIcon,
      label: t('dashboard'),
      to: '/',
    },
    {
      key: 'company',
      icon: WorkOutline,
      label: t('companySettings'),
      to: '/setting/company',
      authKey: 'aside_menu-company_setting',
    },
    {
      key: 'credit-deposit',
      icon: FundIcon,
      label: t('creditDeposit'),
      to: '/credit-deposit',
      extra: fundStatusMap[creditDepositStatus],
      authKey: 'aside_menu-credit_deposit',
    },
    {
      key: 'authority',
      icon: PeopleIcon,
      label: t('authority'),
      to: '/authority',
      authKey: 'aside_menu-authority',
    },
    {
      key: 'system-log',
      icon: LoginIcon,
      label: t('systemLog'),
      to: '/system-log',
      authKey: 'aside_menu-system_log',
    },
    {
      key: 'api-management',
      icon: ApiIcon,
      label: t('apiManagement'),
      to: '/api-management',
      authKey: 'aside_menu-api_management',
    },
  ], [i18n.language, creditDepositPercent])

  const authMenuList = useMemo(() =>
    menuList.filter(item => item.authKey ? checkAuth({ key: item.authKey as AuthKeyType }) : true), [menuList, checkAuth])

  return (
    <UserDiv className="userdiv">
      <UserCard className="usercard" $childLength={authMenuList.length}>

        <UserInfo ispin={isPin} className="userinfo">
          <IconWrap>
            <SFSVG src={UserIcon} />
          </IconWrap>

          <div className="username">
            <SFText display="block" width="172px" level={2} color="grey50">
              <SFAutoEllipsis value={username} tooltip />
            </SFText>
          </div>

          <IconWrap className="username" style={{ marginLeft: '12px' }}>
            <SFSVG className="lamp" src={CircleDoubleIcon} color="grey100" />
          </IconWrap>
        </UserInfo>
        <MenuContent>
          { authMenuList.map(item => (
            <MenuItem className={location.pathname === item.to ? 'current-route' : ''} key={item.key} to={item.to}>
              <SFSVG mr="8px" src={item.icon} color="grey200" />
              <SFText level={2} height="100%" color="grey50" fontWeight="400">
                <SFAutoEllipsis value={item.label} tooltip />
              </SFText>
              { item.extra }
            </MenuItem>
          ))}
        </MenuContent>

      </UserCard>
    </UserDiv>
  )
}


export default ContentUser
