import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCompanyInfoService } from '@/services/broker'

type BrokerConfig = {
  currency: string
  name: string
  email: string
  countryCode: string
  phone: string
}

export const initState: BrokerConfig = {
  currency: 'USD',
  name: '',
  email: '',
  countryCode: '',
  phone: '',
}

const updateCompanyConfig = createAsyncThunk('config/company', async () => {
  const result = await GetCompanyInfoService()
  return {
    currency: 'USD',
    name: result.isError === false ? result.value.name : initState.name,
    email: result.isError === false ? result.value.email : initState.email,
    countryCode: result.isError === false ? result.value.countryCode : initState.countryCode,
    phone: result.isError === false ? result.value.name : initState.phone,
  }
})

const companyConfigSlice = createSlice({
  name: 'companyConfig',
  initialState: initState,
  reducers: {
    setCompanyConfig: (state, action: { payload: BrokerConfig }) => {
      return action.payload
    },
    clearCompanyConfig: () => initState
  },

  extraReducers: (builder) => {
    builder.addCase(updateCompanyConfig.fulfilled, (state, action) => action.payload || initState)
  },
})

export const companyConfigActions = { ...companyConfigSlice.actions, updateCompanyConfig }
export default companyConfigSlice.reducer
