import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetCreditDepositService } from '@/services/credit-deposit'

const initialState: CreditDeposit.GetFunds.Res['trustFunds'] = {
  balance: '0',
  profitShareUnsettled: '0',
  waitingDeposit: false,
  status: 'NORMAL',
  depositDeadline: null
}

const updateCreditDeposit = createAsyncThunk('credit-deposit', async () => {
  const result = await GetCreditDepositService()
  return result.isError === false ? result.value?.trustFunds || initialState : initialState
})

const creditDepositSlice = createSlice({
  name: 'credit-deposit',
  initialState,
  reducers: {
    clearCreditDeposit: () => initialState,
    // setCreditDepositStatus: (state, action: { payload: { status: Common.CreditDepositType, depositDeadline: string } }) => {
    //   return { ...state, status: action.payload.status }
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(updateCreditDeposit.fulfilled, (state, action) => action.payload)
  }
})

export const creditDepositActions = { ...creditDepositSlice.actions, updateCreditDeposit }
export default creditDepositSlice.reducer
