import styled from 'styled-components'
import { SFText } from '@otsofintech/sofinx-ui'

export const I18nLabel = styled(SFText)`
  &:hover {
    color: ${ props => props.theme.palette.grey40 };
  }
`

export const Content = styled.div`
  position: absolute;
  bottom: calc(100% + 12px);
  left: 0;
  box-shadow: 0px 8px 24px -4px ${props => props.theme.palette.defaultShadow1}, 0px 6px 12px -6px ${props => props.theme.palette.defaultShadow2};
  border-radius: 8px;
  background-color: ${ props => props.theme.palette.white };
`

export const OptionWrap = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Option = styled(SFText)`
  padding: 8px 16px;
  width: max-content;
  transition: all .2s;

  &:hover {
    color: ${ props => props.theme.palette.grey60 };
  }
`
