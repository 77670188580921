import { ThemeProvider } from 'styled-components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '@/store'
import { LoadingInto, SFErrorBoundary } from '@otsofintech/sofinx-ui'
import { theme } from '@otsofintech/sofinx-ui/lib/style'
import { GetUserService } from '@/services/user'
import Router from '@/routes'
import { middleware, GlobalStyle } from './middleware'
import useInitData from '@/hooks/useInitData'
import { webSocket } from '@/services/api-helper/webSocket'
import { UserAuth } from './utils/enum'

middleware()

const App = () => {
  const { i18n } = useTranslation()

  const brokerName = useSelector((state: RootState) => state.companyConfig.name)
  useEffect(() => { document.title = brokerName }, [brokerName])

  const [isLoading, setIsLoading] = useState(true)

  const { setLoginData } = useInitData()

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)

  useEffect(() => {
    getInitData()

    async function getInitData () {
      const result = await GetUserService()

      /// 已取得登入資料
      if (!result.isError) {
        changeLanguage(result.value.language)
        
        setLoginData(result.value).then(() => {
          setIsLoading(false)    
        })
      }
      else setIsLoading(false)
      
    }
  }, [useInitData])

  // websocket
  useEffect(() => webSocket.create(), [])
  useEffect(() => { isLogin ? webSocket.getAuth() : webSocket.clearAuth() }, [isLogin])

  const changeLanguage = (language: string) => {
    if (language === i18n.language) return
    localStorage.setItem('language', language)
    i18n.changeLanguage(language)
  }

  return (
    <ThemeProvider theme={theme}>
      <SFErrorBoundary>
        { isLoading ? <LoadingInto /> : <Router /> }
        <GlobalStyle />
      </SFErrorBoundary>
    </ThemeProvider>
  )
}

export default App
