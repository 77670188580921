import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { GetAuditCountService } from '@/services/audit'
import { GetSubscriptionsService } from '@/services/api-key'
import { SubscriptionItems } from '@/utils/enum'

const initialState = {
  identityVerification: 0,
  deposit: 0,
  withdraw: 0,
}

const updateAuditCount = createAsyncThunk('audit/count', async () => {
  const result = await GetAuditCountService()
  if(!result.isError){
    // 如果沒訂閱身份驗證就會把 audit count 的 identityVerification 變 0
    const result2 = await GetSubscriptionsService()
    if(!result2.isError){
      const identityVerificationSubscription = result2?.value.subscriptions?.find(subscription => subscription.feature === SubscriptionItems.IdentityVerification)
      if (identityVerificationSubscription && identityVerificationSubscription.enabled) {
        return result.value
      } else {
        const withoutIdentityVerification = { ...result.value, identityVerification: 0 }
        return withoutIdentityVerification
      }
    }
    return initialState
  }
})


const auditAmountSlice = createSlice({
  name: 'audit-count',
  initialState,
  reducers: {
    clearConfigSymbol: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(updateAuditCount.fulfilled, (state, action) => action.payload)
  }
})

export const auditCountActions = { ...auditAmountSlice.actions, updateAuditCount }
export default auditAmountSlice.reducer
