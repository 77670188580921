import { userinfoActions } from './slices/userInfoReducer'
import { authActions } from './slices/authReducer'
import { symbolsActions } from './slices/symbolsReducer'
import { auditCountActions } from './slices/auditCountReducer'
import { companyConfigActions } from './slices/companyConfigReducer'
import { creditDepositActions } from './slices/creditDepositReducer'
import { userRolePermissionActions } from './slices/userRolePermissionReducer'
import { brokerPermissionActions } from './slices/brokerPermissionReducer'
import { pageTempSliceActions } from './slices/pageTempReducer'
import { wsStateActions } from './slices/wsStateReducer'
import { brokerSubscriptionActions } from './slices/brokerSubscriptionReducer'

export { pageTempSliceActions }

export const setUserInfo = userinfoActions.setUserInfo
export const clearUserInfo = userinfoActions.clearUserInfo

export const setAuth = authActions.setAuth
export const clearAuth = authActions.clearAuth

export const updateConfigSymbol = symbolsActions.updateConfigSymbol
export const clearConfigSymbol = symbolsActions.clearConfigSymbol

export const updateAuditCount = auditCountActions.updateAuditCount

export const updateCompanyConfig = companyConfigActions.updateCompanyConfig
export const setCompanyConfig = companyConfigActions.setCompanyConfig
export const clearCompanyConfig = companyConfigActions.clearCompanyConfig

export const updateCreditDeposit = creditDepositActions.updateCreditDeposit
export const clearCreditDeposit = creditDepositActions.clearCreditDeposit
// export const setCreditDepositStatus = creditDepositActions.setCreditDepositStatus

export const updateUserRolePermission = userRolePermissionActions.updateUserRolePermission
export const setUserRolePermission = userRolePermissionActions.setUserRolePermission

export const clearUserRolePermission = userRolePermissionActions.clearUserRolePermission

export const clearBrokerPermission = brokerPermissionActions.clearBrokerPermission

export const updateBrokerPermission = brokerPermissionActions.updateBrokerPermission

export const updateBrokerSubscriptions = brokerSubscriptionActions.updateBrokerSubscriptions
export const setBrokerSubscriptions = brokerSubscriptionActions.setBrokerSubscriptions

export const clearBrokerSubscriptions = brokerSubscriptionActions.clearBrokerSubscriptions


export const enableWsState = wsStateActions.enable

export const disableWsState = wsStateActions.disable