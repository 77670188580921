import { memo } from 'react'
import { LoadingProgress } from '@otsofintech/sofinx-ui'
import { useGlobalLoading, loader } from '@/hooks/useLoading'

const Loading = memo(() => {
  // Global Loading
  useGlobalLoading()
  const { isLoading } = loader

  return <LoadingProgress isLoading={isLoading} />
}, () => true)

export default Loading
