import { RootState } from '@/store'
import { RoleAuthKeys } from '@/utils/enum'
import { useSelector } from 'react-redux'


const itemAuthConfig = [
  {
    key: 'aside_menu-company_setting' as const,
    read: [RoleAuthKeys.BrokerSettingRead],
    write: [RoleAuthKeys.BrokerSettingWrite],
  },
  {
    key: 'aside_menu-authority' as const,
    read: [RoleAuthKeys.StaffDataRead],
    write: [RoleAuthKeys.StaffDataWrite]
  },
  {
    key: 'aside_menu-system_log' as const,
    read: [RoleAuthKeys.LoggingDataRead],
    write: [],
  },
  {
    key: 'aside_menu-api_management' as const,
    read: [RoleAuthKeys.ApiKeyDataRead],
    write: [RoleAuthKeys.ApiKeyDataWrite]
  },
  {
    key: 'aside_menu-credit_deposit' as const,
    read: [RoleAuthKeys.TrustFundDataRead],
    write: [],
  },
  {
    key: 'aside_menu-indentity_verification' as const,
    read: [RoleAuthKeys.IdentityVerificationSettingRead],
    write: [RoleAuthKeys.IdentityVerificationSettingWrite]
  },
  {
    key: 'aside_menu-platform_settings' as const,
    read: [RoleAuthKeys.MerchantProfitShareRead],
    write: [RoleAuthKeys.MerchantProfitShareWrite]
  },
  {
    key: 'aside_menu-deposit_management' as const,
    read: [RoleAuthKeys.PaymentDataRead],
    write: [RoleAuthKeys.PaymentDataWrite]
  },
  {
    key: 'aside_menu-withdraw_management' as const,
    read: [RoleAuthKeys.PaymentDataRead],
    write: [RoleAuthKeys.PaymentDataWrite]
  },
  {
    key: 'aside_menu-deposit_review' as const,
    read: [RoleAuthKeys.PaymentDataRead],
    write: [RoleAuthKeys.PaymentDataWrite]
  },
  {
    key: 'aside_menu-withdraw_review' as const,
    read: [RoleAuthKeys.PaymentDataRead],
    write: [RoleAuthKeys.PaymentDataWrite]
  },
  {
    key: 'aside_menu-identity-verification_review' as const,
    read: [RoleAuthKeys.IdentityVerificationRequestRead],
    write: [RoleAuthKeys.IdentityVerificationRequestWrite]
  },
  {
    key: 'aside_menu-liquidity' as const,
    read: [RoleAuthKeys.LiquidityRead],
    write: [],
  },
  {
    key: 'aside_menu-investor' as const,
    read: [RoleAuthKeys.InvestorDataRead],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'aside_menu-signal' as const,
    read: [RoleAuthKeys.SignalDataRead],
    write: [RoleAuthKeys.SignalDataWrite],
  },
  {
    key: 'aside_menu-symbol' as const,
    read: [RoleAuthKeys.SymbolDataRead],
    write: [RoleAuthKeys.SymbolDataWrite],
  },
  {
    key: 'aside_menu-rule-group' as const,
    read: [RoleAuthKeys.GroupDataRead],
    write: [RoleAuthKeys.GroupDataWrite],
  },
  {
    key: 'aside_menu-rule-symbol' as const,
    read: [RoleAuthKeys.SymbolRuleDataRead],
    write: [RoleAuthKeys.SymbolRuleDataWrite],
  },
  {
    key: 'aside_menu-rule-liquidity' as const,
    read: [RoleAuthKeys.LiquidityRuleDataRead],
    write: [RoleAuthKeys.LiquidityRuleDataWrite],
  },
  {
    key: 'aside_menu-rule-interest' as const,
    read: [RoleAuthKeys.SwapRuleDataRead],
    write: [RoleAuthKeys.SwapRuleDataWrite],
  },
  {
    key: 'aside_menu-rule-fee' as const,
    read: [RoleAuthKeys.FeeRuleDataRead],
    write: [RoleAuthKeys.FeeRuleDataWrite],
  },
  {
    key: 'aside_menu-competition' as const,
    read: [RoleAuthKeys.CompetitionDataRead],
    write: [RoleAuthKeys.CompetitionDataWrite],
  },
  {
    key: 'aside_menu-report' as const,
    read: [RoleAuthKeys.ReportDataRead],
    write: [],
  },
  {
    key: 'investor-add' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'investor-import' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'investor-detail-overview-referrer' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'investor-detail-overview-group' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'investor-detail-overview-kyc_status' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'investor-detail-overview-withdraw' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'init-update_company_info' as const,
    read: [RoleAuthKeys.BrokerSettingRead],
    write: [],
  },
  {
    key: 'init-update_audit_count' as const,
    read: [RoleAuthKeys.PaymentDataRead],
    write: [],
  },
  {
    key: 'init-update_credit_deposit' as const,
    read: [RoleAuthKeys.TrustFundDataRead],
    write: [],
  },
  {
    key: 'init-update_config_symbol' as const,
    read: [RoleAuthKeys.SymbolDataRead],
    write: [],
  },
  {
    key: 'init-update_broker_permission' as const,
    read: [RoleAuthKeys.StaffDataRead],
    write: [],
  },
  {
    key: 'investor-list-table-checkbox' as const,
    read: [],
    write: [RoleAuthKeys.InvestorDataWrite],
  },
  {
    key: 'merchant-profit-share-input-setting' as const,
    read: [],
    write: [RoleAuthKeys.MerchantProfitShareWrite],
  },
  {
    key: 'announcement-setting' as const,
    read: [],
    write: [RoleAuthKeys.AnnouncementWrite],
  }
]

type AuthType = 'read' | 'write'
const configKeys = itemAuthConfig.map(item => item.key)

export type AuthKeyType = typeof configKeys[number]


export const useBrokerPermission = () => {
  const borkerPermissions = useSelector((state: RootState) => state.brokerPermission)
  const userRolePermissions = useSelector((state: RootState) => state.userRolePermission)

  /// 用key取得權限id
  const getPermissionIdByKeys = (keys: string[]): string[] => {
    let list: string[] = []
    keys?.forEach(item => {
      const role = borkerPermissions.find(permission => permission.name === item)

      if(role) list.push(role.id)
    })

    return list
  }


  /// 用id取得權限key
  const getPermissionKeyByIds = (Ids: string[]): RoleAuthKeys[] => {
    let list: RoleAuthKeys[] = []
    Ids.forEach(item => {
      const role = borkerPermissions.find(permission => permission.id === item)

      if(role) list.push(role.name as RoleAuthKeys)
    })

    return list
  }


  /// 檢查是否擁有權限
  const checkAuth = ({
    key, authType, strickMode = true, permissions,
  }: {
    key: AuthKeyType, authType?: AuthType, strickMode?: boolean, permissions?: string[]
  }): boolean => {

    let auth = itemAuthConfig.find(item => item.key === key)
    const activePermissions = permissions || userRolePermissions

    /// 查無權限
    if(!auth) return false

    switch(authType) {
    /// 查詢是否有write權限
    case 'write': {
      let authKey = [...auth.write]
      let matchCount = 0

      authKey.forEach(item => {
        if(activePermissions.includes(item)) matchCount += 1
      })

      return strickMode ? matchCount === authKey.length : matchCount > 0
    }

    /// 查詢是否有read權限
    case 'read': {
      let authKey = [...auth.read]
  
      let matchCount = 0

      authKey.forEach(item => {
        if(activePermissions.includes(item)) matchCount += 1
      })

      return strickMode ? matchCount === authKey.length : matchCount > 0
    }

    /// 查詢是否有 read & write 權限
    default: {
      let authKey = [...auth.write, ...auth.read]
      let matchCount = 0

      authKey.forEach(item => {
        if(activePermissions.includes(item)) matchCount += 1
      })

      return strickMode ? matchCount === authKey.length : matchCount > 0
    }
    }
  }

  return { getPermissionIdByKeys, checkAuth, getPermissionKeyByIds }
}
