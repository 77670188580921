import { ReactNode, useState } from 'react'
import { Palette } from '@otsofintech/sofinx-ui/lib/style'
import CheckIcon from '@/assets/images/check-done.svg'
import ErrorOutlineIcon from '@/assets/images/error-outline.svg'
import ErrorFillIcon from '@/assets/images/error-fill.svg'
import DelIcon from '@/assets/images/delete-outline.svg'
import StopIcon from '@/assets/images/stop.svg'
import CopyIcon from '@/assets/images/copy-custom.svg'

export type IconTypes = keyof typeof iconMap

type IconMap = {
  [name: string]: { icon: string, color: Palette }
}

export const iconMap: IconMap = {
  apiError: { icon: ErrorFillIcon, color: 'error2' },
  check: { icon: CheckIcon, color: 'primary' },
  error: { icon: ErrorOutlineIcon, color: 'error2' },
  delete: { icon: DelIcon, color: 'error2' },
  stop: { icon: StopIcon, color: 'error2' },
  copy: { icon: CopyIcon, color: 'primary' },
}

type Message = {
  icon: ReactNode | IconTypes
  message: ReactNode
  symbol?: Symbol
}

export let showMessage = (message: Message): void => {}

const useMessage = () => {
  const [message, setMessage] = useState<Message>({
    icon: 'check',
    message: '',
    symbol: Symbol(Date.now()),
  })

  showMessage = (message) => setMessage({ ...message, symbol: Symbol(Date.now()) })

  return {
    messager: message,
    setMessage
  }
}

export default useMessage
