import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { setUserInfo } from '@/store/actions'
import { languageList } from '@/assets/locales/i18n'
import { PutUserLanguageService } from '@/services/user'
import { UserAuth } from '@/utils/enum'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Collapse from '@material-ui/core/Collapse'
import { I18nLabel, Content, OptionWrap, Option } from './style'

const I18nSelector = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)
  const userInfo = useSelector((state: RootState) => state.userInfo)

  const currentLang = useMemo(() => {
    return languageList.find(item => item.value === i18n.language)?.short || ''
  }, [i18n.language])

  const handleOption = (lang: string) => {
    handleClose()
    localStorage.setItem('language', lang)
    i18n.changeLanguage(lang)

    if (isLogin) {
      PutUserLanguageService(lang)
      dispatch(setUserInfo({ ...userInfo, language: lang }))
    }
  }

  const [isOpen, setIsOpen] = useState(false)
  const handleToggle = () => setIsOpen(prev => !prev)
  const handleClose = () => setIsOpen(false)

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div style={{ position: 'relative' }}>
        <I18nLabel
          onClick={handleToggle}
          fontSize="12px"
          lineHeight="22px"
          color={ isOpen ? 'grey40' : 'grey200' }
          pointer
        >
          { currentLang }
        </I18nLabel>

        <Content>
          <Collapse in={isOpen}>
            <OptionWrap>
              {languageList.map(item => (
                <Option
                  level={2}
                  color={ currentLang === item.short ? 'grey60' : 'grey40' }
                  textAlign="center"
                  key={item.value}
                  onClick={() => handleOption(item.value)}
                  pointer
                >
                  {item.text}
                </Option>
              ))}
            </OptionWrap>
          </Collapse>
        </Content>
      </div>
    </ClickAwayListener>
  )
}

export default I18nSelector
