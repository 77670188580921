import { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@/store'
import { UserAuth } from '@/utils/enum'
import AsideMenu from './aside-menu'
import Message from './message'
import useWebSocket from '@/hooks/useWebSocket'
import CreditDepositBottomNotify from './credit-deposit-bottom-notify'
import { updateCreditDeposit } from '@/store/actions'

const Wrap = styled.div<{ islogin: boolean }>`
  display: flex;
  width: 100%;
  height: 100vh;
`

const Main = styled.main<{ ispin: boolean, islogin: boolean }>`
  position: relative;
  left: ${ props => props.islogin ? props.ispin ? '320px' : '88px' : '0' };
  width: calc(100vw - ${props => props.islogin ? props.ispin ? '320px' : '88px' : '0px' });
  height: 100%;
  overflow: auto;
  transition: all .1s;
`

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useDispatch()

  const isLogin = useSelector((state: RootState) => state.auth !== UserAuth.GUEST)


  const [isPin, setIsPin] = useState(window.innerWidth >= 1200)
  const handlePin = () => setIsPin(prev => !prev)
  const closePin = () => setIsPin(false)
  useEffect(() => {
    window.addEventListener('resize', () => window.innerWidth < 1200 && closePin())
  }, [])

  useWebSocket({
    channel: 'Event',
    channelString: 'event#Subscribe@Event',
    unsubscribeString: 'event#Unsubscribe@Event',
    needAuth: true,
    callback: (rawValue: any) => {
      const payload = rawValue.payload
      // const data = toCamel(JSON.parse(payload.data as unknown as string))
      const creditDepositSet = new Set(['BrokerTrustFundsStatusChanged'])
      if(creditDepositSet.has(payload?.type))
        // dispatch(setCreditDepositStatus({status: data?.status, depositDeadline: data?.depositDeadline}))
        dispatch(updateCreditDeposit())
    },
  }, [])

  return (
    <>
      <Wrap islogin={isLogin}>
        { isLogin && <AsideMenu isPin={isPin} onPin={handlePin} /> }
        <Message />

        <Main islogin={isLogin} ispin={isPin}>
          { children }
        </Main>

        { isLogin && <CreditDepositBottomNotify isLogin={isLogin} isPin={isPin} /> }
      </Wrap>
    </>
  )
}

export default Layout
