import { consoleProject, onErrorEvent } from '@otsofintech/sofinx-ui/lib/helper'
import { initLanguage } from '@/assets/locales/i18n'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import utc from 'dayjs/plugin/utc'
import { createGlobalStyle } from 'styled-components'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

export const middleware = () => {
  dayjs.extend(duration)
  dayjs.extend(utc)

  initSentry()
  onErrorEvent()
  initLanguage()
  consoleProject()
}

function initSentry () {
  const isProd = /^.*\.sofinx.com$/.test(window.location.host)
  if (isProd) {
    Sentry.init({
      dsn: 'https://9ef9f95bb90045d2a592b68bc1fb5662@o1361986.ingest.sentry.io/6704862',
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    })
  }
}

export const GlobalStyle = createGlobalStyle`
  * {
    /* 避免mui或其他外部組件覆蓋字體 */
    font-family: Inter, -apple-system, 'system-ui', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif !important;

    box-sizing: border-box;
    text-decoration: none;
    &:disabled { cursor: not-allowed; }
  }

  body {
    margin: 0;
    height: 100%;
    min-height: 100vh;
    background-color: #F8F8F8;
    cursor: default;
  }

  ::selection {
    background: ${ props => props.theme.palette.extend1 };
  }
`
