import { deepCopy, toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'

// 用email登入
export const LoginByEmailService = (query: Auth.LoginByEmail.Request): ApiService<boolean> => {
  const params = toUnderline(deepCopy(query))
  return request.post('/auth/login_by_email', params)
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LoginByEmailService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 確認驗證碼
export const VertifyTokenService = (query: Auth.VertifyToken.Request): ApiService<Auth.VertifyToken.Response> => {
  const params = toCamel(deepCopy(query))
  return request.patch('/auth/verify', params)
    .then(res => ({ isError: false, value: res.data.user }))
    .catch(err => {
      apiErrorMsg('VertifyTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 登出
export const LogoutService = (): ApiService<boolean> => {
  return request.delete('/auth/logout')
    .then(res => ({ isError: false, value: true }) as const)
    .catch(err => {
      apiErrorMsg('LogoutService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 創建Websocket連接認證token，認證後才可訂閱 private 頻道，token有效時間為 10 秒
export const WsTokenService = (): ApiService<Auth.WebSocketToken.Response> => {
  return request.post('/auth/ws_token')
    .then(res => ({ isError: false, value: res.data }))
    .catch(err => {
      apiErrorMsg('WsTokenService', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}