import { toCamel, toUnderline } from '@otsofintech/sofinx-ui/lib/helper'
import { request, apiErrorMsg, ApiService } from '@/services/api-helper'


/// 取得Role列表
export const GetRoles = (): ApiService<Roles.Role.Get.Res> => {
  return request.get('/roles')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRoles', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}


/// 取得每個Role及User的列表
export const GetRoleUsers = (): ApiService<Roles.Role.GetUsers.Res> => {
  return request.get('/roles/users')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRoleUsers', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

/// 新增Role
export const PostRoles = (query: Roles.Role.Post.Req): ApiService<Roles.Role.Post.Res> => {
  const body = toUnderline(query)
  return request.post('/roles/', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostRoles', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

/// 更新Role的詳細資訊
export const PutRoles = (id: string, query: Roles.Role.Put.Req): ApiService<Roles.Role.Put.Res> => {
  const body = toUnderline(query)
  return request.put(`/roles/${id}`, body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PutRoles', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
/// 刪除Role
export const DelRoles = (id: string): ApiService<Boolean> => {
  return request.delete(`/roles/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('DelRoles', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 新增user
export const PostRolesUser = (query: Roles.User.Post.Req) => {
  const body = toUnderline(query)
  return request.post('/roles/users', body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PostRolesUser', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新user
export const GetRolesUser = (id: string): ApiService<Roles.User.GetUser.Res> => {
  return request.get(`/roles/users/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRolesUser', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新user自己
export const GetRolesUserSelf = (): ApiService<Roles.User.GetUser.Res> => {
  return request.get('/roles/users/self')
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('GetRolesUserSelf', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 更新user
export const PutRolesUser = (id: string, query: Roles.User.Put.Req)=> {
  const body = toUnderline(query)
  return request.put(`/roles/users/${id}`, body)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('PutRolesUser', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}

// 刪除user
export const DelRolesUser = (id: string) => {
  return request.delete(`/roles/users/${id}`)
    .then(res => ({ isError: false, value: toCamel(res.data) }))
    .catch(err => {
      apiErrorMsg('DelRolesUser', err.response?.data?.error)
      return { isError: true, value: err.response?.data?.error }
    })
}
