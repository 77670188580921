import { configureStore, combineReducers } from '@reduxjs/toolkit'
import userInfoReducer from './slices/userInfoReducer'
import authReducer from './slices/authReducer'
import symbolsReducer from './slices/symbolsReducer'
import auditCountReducer from './slices/auditCountReducer'
import companyConfigReducer from './slices/companyConfigReducer'
import creditDepositReducer from './slices/creditDepositReducer'
import userRolePermissionReducer from './slices/userRolePermissionReducer'
import brokerPermissionReducer from './slices/brokerPermissionReducer'
import pageTempReducer from './slices/pageTempReducer'
import wsStateReducer from './slices/wsStateReducer'
import borkerSubscriptionReducer from './slices/brokerSubscriptionReducer'

const rootReducer = combineReducers({
  userInfo: userInfoReducer,
  auth: authReducer,
  symbols: symbolsReducer,
  auditCount: auditCountReducer,
  companyConfig: companyConfigReducer,
  creditDeposit: creditDepositReducer,
  userRolePermission: userRolePermissionReducer,
  brokerPermission: brokerPermissionReducer,
  borkerSubscription: borkerSubscriptionReducer,
  pageTemp: pageTempReducer,
  wsState: wsStateReducer,
})

const store = configureStore({
  reducer: rootReducer,
})

export type RootState = ReturnType<typeof store.getState>

export default store
